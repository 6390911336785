import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'
import { Link } from 'react-router-dom'

import { Spinner, Paper, Screen } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseTrustedAppsBreadCrumbs from 'domains/trustedApps/components/BaseTrustedAppsBreadCrumbs'
import TitleBar from 'domains/trustedApps/components/TrustedAppDetails/TitleBar'
import IIMSApplication, {
  getIMSAppVettings,
} from 'domains/trustedApps/models/IIMSApplication'
import useTrustedApp from 'domains/trustedApps/hooks/useTrustedApp'
import {
  getRoute,
  TRUSTED_APPS_ROUTE,
} from 'domains/application/navigation/routes'
import VettedIcon from 'domains/trustedApps/components/icons/VettedIcon'
import TrustedAppVettingWelcomeText from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingWelcomeText'
import {
  getVettingAuthor,
  getVettingAuthorTitle,
  VETTING_RUBRIC_TYPE_TITLE_MAP,
} from 'domains/trustedApps/models/IIMSApplicationVetting'
import BlankSlate from 'lib/components/BlankSlate'
import TrustedAppListItem from 'domains/trustedApps/components/TrustedAppListItem'

export default function TrustedAppVettingsScreen(props: any) {
  const [id, imsAppDetails, , pending, parentOrgId] = useTrustedApp(props)

  if (pending) {
    return (
      <Screen>
        <DocumentTitle title="Application Details" />
        <BaseTrustedAppsBreadCrumbs {...props} crumbs={[{ name: '...' }]} />

        <Spinner centered={true} />
      </Screen>
    )
  }

  const imsApp = imsAppDetails as IIMSApplication
  const title = R.pathOr('', ['productName'], imsApp)
  const vettings = getIMSAppVettings(imsApp)

  const baseAppRoute = `${TRUSTED_APPS_ROUTE}${
    parentOrgId ? `/shared/${parentOrgId}` : ''
  }`

  return (
    <Screen>
      <DocumentTitle title={`${title} - Vettings`} />
      <BaseTrustedAppsBreadCrumbs
        {...props}
        crumbs={[
          { name: title, route: getRoute(baseAppRoute, id) },
          { name: 'Vettings' },
        ]}
      />

      <Paper>
        <TitleBar
          app={imsApp}
          rightIcon={<VettedIcon width={40} height={40} />}
          rightTitle="App Vetting"
        />
        <TrustedAppVettingWelcomeText />

        {vettings.length < 1 && <BlankSlate title="No App Vettings" />}
        {vettings.map((vetting, index) => {
          const authorName = getVettingAuthor(vetting)
          const authorTitle = getVettingAuthorTitle(vetting)
          const vettingDTPrefix = `vetting-${index}`
          return (
            <TrustedAppListItem key={`vetting--${index}`}>
              <div className="space-y-1">
                <h2
                  className="text-xl font-normal"
                  data-test={`${vettingDTPrefix}-title`}
                >
                  <span className="font-bold text-2xl">{authorName}</span>
                  {utils.hasValue(authorTitle) ? `, ${authorTitle}` : ''}
                  {utils.hasValue(vetting.rubricType) ? (
                    <div className="bg-grey-100 rounded-md p-2 ml-2">
                      {`${
                        VETTING_RUBRIC_TYPE_TITLE_MAP[
                          vetting.rubricType ?? 'privacy'
                        ]
                      }`}
                    </div>
                  ) : (
                    ''
                  )}
                </h2>
                <p className="text-sm" data-test={`${vettingDTPrefix}-date`}>
                  {utils.date.formatDate(vetting.creationDate, 'MM/DD/YYYY')}
                </p>
              </div>

              <div
                className="flex flex-col justify-end align-end"
                data-test={`${vettingDTPrefix}-view-btn`}
              >
                <Link
                  to={getRoute(baseAppRoute, id, ['vettings', index])}
                  className="bg-complete text-white font-bold my-3 px-10 py-2"
                >
                  View
                </Link>
              </div>
            </TrustedAppListItem>
          )
        })}
      </Paper>
    </Screen>
  )
}
