let orgId = '154'

const devUser = {
  id: '196',
  organization: {
    href: `http://dev.1edtech.net/swb-endpoint/swb/v1p0/orgs/${orgId}`,
    id: `${orgId}`,
    type: 'Organization',
  },
  organizationName: 'IMS',
  username: 'jsingleton@imsglobal.org',
  firstName: 'Jackson',
  lastName: 'Singleton',
  jobTitle: 'Frontend Dude',
  contactType: 'none',
  email: 'jsingleton@imsglobal.org',
  lastActive: 1668623124179,
  ims: true,
  userRole: ['ADMIN'],
  primaryContact: false,
}

export default devUser
