import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { Button, Spinner } from 'lib/components'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CustomRating } from './types'
import { CustomRatingRow } from './TrustedAppsCustomRatingSetupRow'
import { usePutRatings } from './usePutRatings'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_RATINGS_RECORD } from 'lib/records/modules/trustedAppsRatings'
import { TrustedAppRating } from '../../models/ITrustedApplication'
import {
  getRandomIconColor,
  getRandomIconIdentifier,
} from '../../constants/trustedAppsCustomRatingSetup'
import { ERROR_TOAST, showToast, SUCCESS_TOAST } from 'lib/utils/toast'
import styles from './TrustedAppsCustomRatingSetup.module.css'

interface TrustedAppsCustomRatingProps {
  height: number
}

export function TrustedAppsCustomRating({
  height,
}: TrustedAppsCustomRatingProps) {
  const [ratings, setRatings] = useState<CustomRating[]>([])
  const [data, isFetching] = useRecordEntity({
    record: TRUSTED_APPS_RATINGS_RECORD,
    id: 1,
    noReFetch: true,
  })

  const ratingsData: TrustedAppRating[] | undefined = data?.ratings

  const [putRatings, putting] = usePutRatings()

  const addOne = () => {
    setRatings((prev) => [
      ...prev,
      {
        ratingId: Math.random(),
        isNew: true,
        iconColor: getRandomIconColor(),
        iconIdentifier: getRandomIconIdentifier(),
        ratingText: '',
        isEmbedded: false,
      },
    ])
  }

  const removeOne = (removingRating: CustomRating) => {
    setRatings((prev) =>
      prev.filter((rating) => {
        return removingRating.ratingId !== rating.ratingId
      }),
    )
  }

  const updateRating = (id: number, rating: Partial<CustomRating>) => {
    setRatings((prev) =>
      prev.map((r) => {
        if (r.ratingId === id) {
          return { ...r, ...rating }
        }

        return r
      }),
    )
  }

  const onSave = async () => {
    try {
      await putRatings(ratings)
      showToast(SUCCESS_TOAST, 'Custom Ratings saved successfully')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      showToast(ERROR_TOAST, 'Failed to save Custom Ratings')
    }
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const items = [...ratings]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setRatings(items.map((item, index) => ({ ...item, ratingIndex: index })))
  }

  useEffect(() => {
    if (ratingsData) {
      setRatings(ratingsData.map((rating) => ({ ...rating, isNew: false })))
    }
  }, [ratingsData])

  return (
    <DetailsTopWidget
      title="Custom Ratings"
      dataTest="trustedapps-custom-rating-widget"
      height={height}
    >
      {isFetching && <Spinner />}

      {!isFetching && (
        <div className="px-4">
          <div className={styles.description}>
            Apps sorted by rating will follow this order, drag to change:
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="ratings">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col mt-2"
                >
                  {ratings.map((row, index) => {
                    const id = row.ratingId.toString()

                    return (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                          <CustomRatingRow
                            containerRef={provided.innerRef}
                            {...provided.draggableProps}
                            key={row.ratingId}
                            rating={row}
                            onRemove={() => removeOne(row)}
                            dragHandleProps={provided.dragHandleProps}
                            className="mb-2"
                            onChangeText={(ratingText) => {
                              updateRating(row.ratingId, { ratingText })
                            }}
                            onChangeIconIdentifier={(iconIdentifier) => {
                              updateRating(row.ratingId, { iconIdentifier })
                            }}
                            onChangeIconColor={(iconColor) => {
                              updateRating(row.ratingId, { iconColor })
                            }}
                            onChangeEmbedded={(isEmbedded) => {
                              updateRating(row.ratingId, { isEmbedded })
                            }}
                          />
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
            className="w-full bg-silver hover:bg-violet-95 text-gray rounded-md flex py-6 items-center justify-center cursor-pointer hover:text-violet-80"
            onClick={addOne}
          >
            <i className="fas fa-plus text-xl font-bold mr-2"></i>Add New Rating
          </div>

          <div className="flex w-full justify-end p-4 mt-4 bottom-0 sticky bg-white">
            <Button
              type="button"
              variant="complete"
              onClick={onSave}
              pending={putting}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </DetailsTopWidget>
  )
}
