import { getRequest } from 'lib/api/api'
import { useEffect, useState } from 'react'
import { TrustedAppRating } from '../models/ITrustedApplication'

interface IPublicApplication {
  rating?: TrustedAppRating
  logo?: string
  privacyUrl?: string
  title: string
}

interface UseQueryPublicApplicationsOptions {
  orgId: string | number
}

export function useQueryPublicApplications({
  orgId,
}: UseQueryPublicApplicationsOptions) {
  const [loading, setLoading] = useState(false)
  const [applications, setApplications] = useState<IPublicApplication[]>([])

  useEffect(() => {
    setLoading(true)

    getRequest(`trustedapps/public/organizations/${orgId}/applications`, {
      sort: 'rating:ascending',
    })
      .then((res) => setApplications(res.data.applications))
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [orgId])

  return { data: applications, loading }
}
