import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { Spinner, Paper, Screen } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseTrustedAppsBreadCrumbs from 'domains/trustedApps/components/BaseTrustedAppsBreadCrumbs'
import TitleBar from 'domains/trustedApps/components/TrustedAppDetails/TitleBar'
import IIMSApplication, {
  IIMSApplicationVersion,
  IIMSApplicationVersionCertification,
} from 'domains/trustedApps/models/IIMSApplication'
import useTrustedApp from 'domains/trustedApps/hooks/useTrustedApp'
import {
  getRoute,
  TRUSTED_APPS_ROUTE,
} from 'domains/application/navigation/routes'
import BlankSlate from 'lib/components/BlankSlate'
import CertifiedIcon from 'domains/trustedApps/components/icons/CertifiedIcon'
import TrustedAppListItem from 'domains/trustedApps/components/TrustedAppListItem'

interface IVersionedCert extends IIMSApplicationVersionCertification {
  version: string
}

export default function TrustedAppCertsScreen(props: any) {
  const [id, imsAppDetails, , pending, parentOrgId] = useTrustedApp(props)

  if (pending) {
    return (
      <Screen>
        <DocumentTitle title="Application Details" />
        <BaseTrustedAppsBreadCrumbs {...props} crumbs={[{ name: '...' }]} />

        <Spinner centered={true} />
      </Screen>
    )
  }

  const imsApp = imsAppDetails as IIMSApplication
  const title = R.pathOr('', ['productName'], imsApp)
  const versions = R.pathOr(
    [],
    ['versions'],
    imsApp,
  ) as IIMSApplicationVersion[]
  const certs = versions.reduce((agg, version) => {
    const versionCerts = R.pathOr(
      [],
      ['certs'],
      version,
    ) as IIMSApplicationVersionCertification[]
    return [
      ...agg,
      ...versionCerts.map(
        (cert) =>
          ({
            version: version.version,
            ...cert,
          } as IVersionedCert),
      ),
    ]
  }, [] as IVersionedCert[])
  certs.sort((c1, c2) => (c1.imsCertDate > c2.imsCertDate ? -1 : 1))

  return (
    <Screen>
      <DocumentTitle title={`${title} - Certifications`} />
      <BaseTrustedAppsBreadCrumbs
        {...props}
        crumbs={[
          {
            name: title,
            route: getRoute(
              `${TRUSTED_APPS_ROUTE}${
                parentOrgId ? `/shared/${parentOrgId}` : ''
              }`,
              id,
            ),
          },
          { name: 'Certifications' },
        ]}
      />

      <Paper>
        <TitleBar
          app={imsApp}
          rightIcon={<CertifiedIcon width={40} height={40} />}
          rightTitle="Certifications"
        />

        <p className="text-lg my-6">
          {title} has completed conformance testing for the following 1EdTech
          Standards
        </p>

        <div>
          {certs.length < 1 && <BlankSlate title="No Certifications" />}
          {certs.map((cert, index) => {
            const certDTPrefix = `cert-${index}`
            return (
              <TrustedAppListItem key={certDTPrefix}>
                <div className="space-y-1">
                  <h2
                    className="text-2xl font-bold"
                    data-test={`${certDTPrefix}-title`}
                  >
                    {cert.imsCertTitle}
                  </h2>

                  <p className="text-sm" data-test={`${certDTPrefix}-date`}>
                    Certified on{' '}
                    {utils.date.formatDate(cert.imsCertDate, 'YYYY-MM-DD')}
                  </p>
                </div>

                <div className="flex flex-col justify-end align-end">
                  <p
                    className="text-sm font-bold"
                    data-test={`${certDTPrefix}-version`}
                  >
                    {cert.version}
                  </p>
                </div>
              </TrustedAppListItem>
            )
          })}
        </div>
      </Paper>
    </Screen>
  )
}
