import {
  UpdateTrustedAppSurveyQuestionsRequest,
  UpdateTrustedAppSurveyQuestionsResponse,
} from 'domains/trustedApps/models/ITrustedAppsCustomQuestions'
import { useState } from 'react'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { TRUSTED_APPS_SURVEYS_RECORD } from 'lib/records/modules/trustedAppsSurveys'
import { useQueryTrustedAppsSurveys } from './useQueryTrustedAppsSurveys'

const updateQuestions = async (
  data: UpdateTrustedAppSurveyQuestionsRequest,
) => {
  const res = await updateRecord(TRUSTED_APPS_SURVEYS_RECORD, '1', data, true)
  return res.data as UpdateTrustedAppSurveyQuestionsResponse
}

export const useUpdateCustomQuestions = () => {
  const { refetch } = useQueryTrustedAppsSurveys()
  const [pending, setPending] = useState(false)

  const update = async (data: UpdateTrustedAppSurveyQuestionsRequest) => {
    setPending(true)
    try {
      const result = await updateQuestions(data)
      await refetch()
      return result
    } catch (error) {
      throw error
    } finally {
      setPending(false)
    }
  }

  return [update, pending] as const
}
