import React from 'react'
import * as R from 'ramda'
import clsx from 'clsx'
import { Transition } from '@headlessui/react'

import * as vettings from 'domains/trustedApps/models/IIMSApplicationVetting'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'
import { utils } from '@ims/1edtech-frontend-common'
import TrustedAppVettingSelectionResults from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingSelectionResults'
import HTML from 'lib/components/HTML'

type TrustedAppVettingResultsProps = {
  vetting: IIMSApplicationVetting
}

export default function TrustedAppVettingResults(
  props: TrustedAppVettingResultsProps,
) {
  const [visibleNotes, setVisibleNotes] = React.useState<{
    [key: string]: boolean
  }>({})

  const toggleNoteVisible = (key: string) => () =>
    setVisibleNotes(
      R.assoc(key, !R.pathOr(false, [key], visibleNotes), visibleNotes),
    )

  const rubricSections = {
    privacy: [
      vettings.RUBRIC_GENERAL_PREFIX,
      vettings.RUBRIC_DATA_COLLECTED_PREFIX,
      vettings.RUBRIC_SECURITY_PREFIX,
      vettings.RUBRIC_THIRD_PARTY_PREFIX,
      vettings.RUBRIC_ADVERTISING_PREFIX,
    ],
    security: [
      vettings.RUBRIC_GENERAL_PREFIX,
      vettings.SECURITY_RUBRIC_DOCUMENTATION_PREFIX,
      vettings.SECURITY_RUBRIC_SYSTEMS_MGMT_PREFIX,
      vettings.SECURITY_RUBRIC_THIRD_PARTY_PREFIX,
      vettings.SECURITY_RUBRIC_DATA_PREFIX,
    ],
    access: [
      vettings.RUBRIC_GENERAL_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_DOCUMENTATION_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_PROCUREMENT_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_CONFORMANCE_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_ALTERNATIVES_PREFIX,
    ],
    ai: [vettings.RUBRIC_GENERAL_PREFIX, vettings.AI_RUBRIC_AI_PREFIX],
  }

  const sections = rubricSections[
    props.vetting.rubricType as keyof typeof rubricSections
  ]
    .map((prefix) => {
      return {
        title: vettings.RUBRIC_PREFIX_TITLE_MAP[prefix],
        prefix,
        selections: props.vetting.selections
          .filter((selection) => selection.question.startsWith(prefix))
          .sort((a, b) => {
            let numA = parseInt(a.question.replace(/\D/g, ''), 10)
            let numB = parseInt(b.question.replace(/\D/g, ''), 10)
            return numA - numB
          }),
      }
    })
    .filter((section) => utils.hasValue(section.selections))

  let totalIndex = 0
  return (
    <div>
      <div className="grid grid-cols-vetting-report gap-3 mb-1">
        <h3 className="font-bold text-xl lg:text-2xl col-span-2">
          Rubric Area
        </h3>
        <h3 className="font-bold text-xl lg:text-2xl text-center w-full">
          Expectations
        </h3>
        <h3 className="font-bold text-xl lg:text-2xl">Notes</h3>
      </div>
      <div className="my-3 border-b border-black w-full" />

      <div className="grid grid-cols-vetting-report mb-1">
        <div className="col-span-2" />
        <div className="flex flex-row items-center text-textLight">
          <h4 className="flex-1 font-normal text-lg mb-1 text-center w-full">
            Meets
          </h4>
          <h4 className="flex-1 font-normal text-lg mb-1 text-center w-full">
            Partially
          </h4>
          <h4 className="flex-1 font-normal text-lg mb-1 text-center w-full">
            Unmet
          </h4>
        </div>
      </div>

      <div
        className="grid gap-y-3 gap-x-4"
        style={{ gridTemplateColumns: 'auto 1fr' }}
      >
        {sections.map((section, index) => {
          const count = section.selections.length

          return (
            <>
              <div
                className="flex items-center px-2 py-4"
                style={{
                  backgroundColor: index % 2 === 0 ? '#509BD8' : '#1B7CDD',
                  minHeight: 64,
                }}
              >
                <div
                  className="font-bold text-xl text-white uppercase text-center"
                  style={{
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                    maxHeight: 200,
                  }}
                >
                  {count > 1
                    ? section.title
                    : `${section.title}`.substring(0, 1)}
                </div>
              </div>

              <div className="flex flex-1 flex-col space-y-3 ml-16">
                {section.selections
                  .sort((a, b) => (a as any).question - (b as any).question)
                  .map((selection, selIndex) => {
                    const notesKey = `${section.prefix}-${selIndex}`
                    const notesVisible = R.pathOr(
                      false,
                      [notesKey],
                      visibleNotes,
                    )
                    const overallIndex = totalIndex
                    totalIndex++

                    const title = selection.question.replace(
                      section.prefix,
                      `${section.prefix}Q`,
                    )

                    return (
                      <div key={`selection-${section.title}-${selIndex}`}>
                        <div
                          key={`selection-${section.title}-${selIndex}`}
                          className="w-full grid grid-cols-vetting-report-row"
                        >
                          <div className="p-3 bg-gray-100">
                            <p>
                              <span className="font-bold">{title}</span>{' '}
                              {selection.questionText.substring(
                                selection.questionText.indexOf('-') + 1,
                              )}
                            </p>
                            <p className="text-textLight text-xs">
                              <span className="text-text font-bold">ANSWER:</span>{' '}
                              {selection.userSelectionText}
                            </p>
                          </div>

                          <TrustedAppVettingSelectionResults
                            selection={selection}
                            index={overallIndex}
                          />

                          <div
                            className="flex flex-row items-center justify-center cursor-pointer text-text hover:text-complete"
                            data-test={`rubric-notes-${overallIndex}-btn`}
                            onClick={
                              utils.hasValue(selection.userNotes)
                                ? toggleNoteVisible(notesKey)
                                : undefined
                            }
                          >
                            {utils.hasValue(selection.userNotes) && (
                              <i
                                className={clsx('text-lg', {
                                  'fas fa-eye': !notesVisible,
                                  'fas fa-eye-slash': notesVisible,
                                })}
                              />
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-vetting-report-row">
                          <div className="col-span-2">
                            <Transition
                              show={notesVisible}
                              enter="transition-opacity ease-linear duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="transition-opacity ease-linear duration-300"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <div
                                className="p-3"
                                data-test={`rubric-notes-${overallIndex}`}
                              >
                                <HTML>{selection.userNotes}</HTML>
                              </div>
                            </Transition>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}
