import React from 'react'
import clsx from 'clsx'

import { Dropdown } from 'lib/components'
import { TrustedAppRating } from 'domains/trustedApps/models/ITrustedApplication'
import { TrustedAppsRatingChip, UNRATED } from './TrustedAppsRatingChip'

interface SelectRatingItemProps {
  rating: TrustedAppRating
  onClick(): void
}

function SelectRatingItem(props: SelectRatingItemProps) {
  return (
    <div
      className={clsx('p-3 cursor-pointer hover:bg-violet-95')}
      onClick={props.onClick}
      data-test={`app-status-${props.rating.ratingId}`}
    >
      <TrustedAppsRatingChip rating={props.rating} />
    </div>
  )
}

interface TrustedAppsRatingSelectProps {
  applicationId: number
  ratingId: number | null
  onChange: (ratingId?: number) => void
  ratings: TrustedAppRating[]
}

export function TrustedAppsRatingSelect(props: TrustedAppsRatingSelectProps) {
  const rating = props.ratings.find(
    (rating) => rating.ratingId === props.ratingId,
  )

  const ratings: any = [...props.ratings, UNRATED]

  return (
    <Dropdown
      target={
        <div
          data-test={`selected-app-status-selector-${props.applicationId}`}
          className="flex flex-col justify-center w-full h-full px-4"
        >
          <TrustedAppsRatingChip rating={rating} />
        </div>
      }
      showChevron={true}
    >
      <div className="bg-white shadow-md rounded-md overflow-hidden">
        {ratings?.map((rating: any, i: number) => (
          <SelectRatingItem
            rating={rating}
            onClick={() => props.onChange(rating.ratingId)}
            key={i}
          />
        ))}
      </div>
    </Dropdown>
  )
}
