import React from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'

import { ModalWrapper, SearchBox } from 'lib/components'
import FormSelect from 'lib/components/modern/Select/FormSelect'
import trackTrustedAppsAnalytics from 'domains/trustedApps/utils/trackTrustedAppsAnalytics'
import useTrustedAppsSupplierOptions from 'domains/trustedApps/hooks/useTrustedAppsSupplierOptions'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_RATINGS_RECORD } from 'lib/records/modules/trustedAppsRatings'
import { TrustedAppRating } from '../models/ITrustedApplication'

import { useQueryTrustedAppsSurveys } from 'domains/trustedApps/hooks/useQueryTrustedAppsSurveys'
import { GetTrustedAppSurveyQuestionsResponse } from '../models/ITrustedAppsCustomQuestions'
import { useQueryTrustedAppsTags } from '../hooks/useQueryTrustedAppsTags'

export type TrustedAppsAdvancedSearchFilter = {
  rating: TrustedAppRating[]
  orgName: string[]
  licensingExpirationDate: number | null
  licensingFees?: string | null
  surveyResponseId: number[]
  tag: string[]
}

export const DEFAULT_TA_FILTER_STATE: TrustedAppsAdvancedSearchFilter = {
  rating: [],
  orgName: [],
  licensingExpirationDate: null,
  licensingFees: null,
  surveyResponseId: [],
  tag: [],
}

export const getCurrentTrustedAppAdvancedSearchFilters = (
  filter: TrustedAppsAdvancedSearchFilter,
): any[] =>
  Object.keys(filter)
    .map((key) => {
      return filter[key as keyof TrustedAppsAdvancedSearchFilter]
    })
    .filter((f) => {
      return Array.isArray(f) ? f.length : f !== null && f !== undefined
    })

export const getTrustedAppAdvancedSearchFilterCount = (
  filter: TrustedAppsAdvancedSearchFilter,
): number =>
  getCurrentTrustedAppAdvancedSearchFilters(filter).reduce(
    (sum, f) => sum + (Array.isArray(f) ? f.length : 1),
    0,
  )

const TRACK_EVENT_ACTION = 'advanced_search'

type Props = {
  query: string
  filter: TrustedAppsAdvancedSearchFilter
  isOpen: boolean
  onRequestClose: () => void
  onSearch: (query: string, filters: TrustedAppsAdvancedSearchFilter) => any
}

export default function TrustedAppsAdvancedSearchModal(props: Props) {
  const [query, setQuery] = React.useState(props.query)

  const tagsQuery = useQueryTrustedAppsTags()
  const surveysQuery = useQueryTrustedAppsSurveys()

  React.useEffect(() => {
    if (props.isOpen) setQuery(props.query)
  }, [props.isOpen, props.query])

  // const [tools = [], toolsPending] = useTrustedAppsTooluses()

  const [
    initialSupplierOptions,
    supplierOptions,
    resetSupplierOptions,
    getSupplierOptions,
  ] = useTrustedAppsSupplierOptions()

  const onSubmit = async (
    values: TrustedAppsAdvancedSearchFilter,
    bag: FormikHelpers<TrustedAppsAdvancedSearchFilter>,
  ) => {
    trackTrustedAppsAnalytics(TRACK_EVENT_ACTION, values)
    props.onSearch(query, values)
    props.onRequestClose()
    bag.setSubmitting(false)
  }

  const renderQuestion = (
    question: GetTrustedAppSurveyQuestionsResponse['questions'][number],
    formik: FormikProps<TrustedAppsAdvancedSearchFilter>,
  ) => {
    const responses = question.responses || []

    const responseIds = responses.map((r) => r.id)
    const selected = formik.values.surveyResponseId.filter((id) =>
      responseIds.includes(id),
    )

    const getPlaceholder = (): string => {
      switch (question.questionType) {
        case 'SINGLE_RESPONSE_FROM_LIST':
        case 'SINGLE_RESPONSE_FROM_LIST_UPDATABLE':
          return 'Select one'
        case 'MULTIPLE_RESPONSES_FROM_LIST_UPDATABLE':
          return 'Select'
        default:
          return 'Select'
      }
    }

    const placeholder: string = getPlaceholder()
    const isMultiselect = question.questionType.includes('MULTIPLE_RESPONSE')

    return (
      <FormSelect
        label={question.questionText}
        name={`surveyResponseId.${question.id}`}
        selected={
          isMultiselect ? selected : selected.length > 0 ? selected[0] : null
        }
        onChange={(value: number | number[] | null) => {
          if (value === null) {
            const filteredValues = formik.values.surveyResponseId.filter(
              (id) => !responseIds.includes(id),
            )
            formik.setFieldValue('surveyResponseId', filteredValues)
          } else if (Array.isArray(value)) {
            const filteredValues = formik.values.surveyResponseId.filter(
              (id) => !selected.includes(id),
            )

            formik.setFieldValue('surveyResponseId', [
              ...filteredValues,
              ...value,
            ])
          } else {
            const filteredValues = formik.values.surveyResponseId.filter(
              (id) => !selected.includes(id),
            )
            formik.setFieldValue('surveyResponseId', [...filteredValues, value])
          }
        }}
        placeholder={placeholder}
        options={responses.map((s) => ({
          label: s.responseText,
          value: s.id,
        }))}
        multiple={isMultiselect}
        extensible={false}
      />
    )
  }

  const [ratingsResponse] = useRecordEntity({
    record: TRUSTED_APPS_RATINGS_RECORD,
    id: 1,
    noReFetch: true,
  })

  const ratings: TrustedAppRating[] = ratingsResponse?.ratings

  return (
    <Formik initialValues={props.filter} onSubmit={onSubmit} enableReinitialize>
      {(formProps) => {
        const activeFilterCount = getTrustedAppAdvancedSearchFilterCount(
          formProps.values,
        )

        const onClearAll = () => {
          setQuery('')
          formProps.setValues(DEFAULT_TA_FILTER_STATE)
        }
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title="Filter"
            actions={[
              {
                text: 'Search',
                variant: 'start',
                extra: { type: 'submit' },
                onClick: formProps.submitForm,
              },
              ...(activeFilterCount > 0
                ? [
                    {
                      text: `Clear All (${activeFilterCount})`,
                      variant: 'gray-400',
                      onClick: onClearAll,
                    },
                  ]
                : []),
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  formProps.resetForm()
                  props.onRequestClose()
                },
              },
            ]}
            pending={formProps.isSubmitting}
            maxWidth={['100%', 820, 900]}
          >
            <div className="flex flex-col space-y-3 bg-white pb-16">
              <SearchBox
                name="search"
                // trackEvent={trackAnalytics}
                // trackEventAction={`${trackAnalyticsCategory}-search`}
                onChange={setQuery}
                value={query}
                placeholder="Search..."
                width="100%"
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormSelect
                  label="Rating"
                  name="rating"
                  selected={formProps.values.rating}
                  onChange={(value: any) =>
                    formProps.setFieldValue('rating', value)
                  }
                  placeholder="Select"
                  options={
                    ratings
                      ? ratings.map((r, i) => ({
                          label: r.ratingText,
                          value: i,
                        }))
                      : []
                  }
                  multiple
                />
                <FormSelect
                  label="Supplier"
                  name="orgName"
                  selected={formProps.values.orgName}
                  onChange={(value: any) => {
                    formProps.setFieldValue('orgName', value)
                    resetSupplierOptions()
                  }}
                  placeholder="Select"
                  options={supplierOptions}
                  initialOptions={initialSupplierOptions}
                  onSearchChange={getSupplierOptions}
                  multiple
                />
                {surveysQuery.data
                  ?.sort((a, b) => a.questionText.localeCompare(b.questionText))
                  .map((question) => renderQuestion(question, formProps))}
              </div>
              <div>
                <hr className="border-silver my-4" />
                <FormSelect
                  label="Application Tags"
                  name="tags"
                  selected={
                    formProps.values.tag &&
                    formProps.values.tag.sort((a, b) => a.localeCompare(b))
                  }
                  onChange={(value: any) => {
                    formProps.setFieldValue('tag', value)
                  }}
                  placeholder="Select"
                  options={(tagsQuery.data ?? [])
                    .map((s) => ({
                      label: s,
                      value: s,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  multiple
                />
              </div>
            </div>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
