import { useQueryPublicApplications } from 'domains/trustedApps/hooks/useQueryPublicApplications'
import { Spinner, Text } from 'lib/components'
import React from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import TrustedAppLogo from 'domains/trustedApps/components/TrustedAppsList/TrustedAppLogo'

const EmbeddableTrustedAppsScreen = () => {
  const params = useParams<{ orgId: string }>()
  const { data } = useQueryPublicApplications({ orgId: params.orgId })

  if (!data) {
    return (
      <div className="flex align-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2 py-3 px-3 bg-white">
      {data.map((app, index) => {
        return (
          <div
            key={index}
            className="grid items-center gap-3 py-2 px-2 shadow-md"
            style={{
              gridTemplateColumns:
                'minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr)',
            }}
          >
            <div>
              {app.rating ? (
                <div className="inline-flex w-fit items-center gap-2 font-bold py-2 px-6 rounded-full border border-silver">
                  <i
                    className={clsx(`fas text-2xl`, app.rating.iconIdentifier)}
                    style={{ color: app.rating.iconColor }}
                  />
                  <Text>{app.rating.ratingText}</Text>
                </div>
              ) : (
                <i className="fas fa-minus text-2xl" color="ta-gray-2" />
              )}
            </div>

            <TrustedAppLogo title={app.title} logo={app.logo} />

            <Text className="text-lg font-bold ml-2 truncate">{app.title}</Text>

            <a
              href={app.privacyUrl}
              target="_blank"
              rel="noreferrer"
              className="ml-2"
            >
              Privacy Policy
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default EmbeddableTrustedAppsScreen
