import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import clsx from 'clsx'
import * as Yup from 'yup'

import { postRequest } from 'lib/api/api'
import FormSelect from 'lib/components/modern/Select/FormSelect'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import {
  Button,
  Paper,
  Screen,
  FormInput,
  View,
  InputLabel,
} from 'lib/components'
import { SelectOptionProps } from 'lib/components/modern/Select/SelectOption'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseTrustedAppsBreadCrumbs from 'domains/trustedApps/components/BaseTrustedAppsBreadCrumbs'
import { Formik, Form, FormikHelpers } from 'formik'
import {
  VETTING_COMPLIANCE_TITLE_MAP,
  VettingRubricScores,
} from 'domains/trustedApps/models/IIMSApplicationVetting'
import { meSelector } from 'domains/authentication/selectors/me'
import RubricQuestion from 'domains/trustedApps/components/AddCustomApps/RubricQuestion'
import { showToast } from 'lib/utils/toast'
import { DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE } from 'domains/application/navigation/routes'
import useTrustedAppsSupplierOptions from 'domains/trustedApps/hooks/useTrustedAppsSupplierOptions'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_RATINGS_RECORD } from 'lib/records/modules/trustedAppsRatings'
import { TrustedAppsRatingSelect } from 'domains/trustedApps/components/AddTrustedApps/TrustedAppsRatingSelect'

import rubricData from 'domains/trustedApps/models/referenceDataRubric.json'
import { TrustedAppRating } from '../models/ITrustedApplication'

interface ICustomAppVetting {
  creation_date: number
  url: string
  author_first_name: string
  author_last_name: string
  author_job_title: string
  author_org_name: string
  rubric_version: number
  coppa_compliance: string
  ferpa_compliance: string
  gdpr_compliance: string
  age_restricted: string
  accessibility_url: string
  cookie_url: string
  privacy_url: string
  security_url: string
  tos_url: string
  selections: any[]
}

interface IFormValues {
  appName: string
  appUrl: string
  appSupplierName?: string
  appSupplierId?: string
  appDescription?: string
  appRating?: TrustedAppRating
  vetting: ICustomAppVetting
  vettingRubric: any
}

const vettingComplianceOptions: SelectOptionProps[] = [
  {
    label: VETTING_COMPLIANCE_TITLE_MAP.Y,
    value: 'Y',
  },
  {
    label: VETTING_COMPLIANCE_TITLE_MAP.N,
    value: 'N',
  },
  {
    label: VETTING_COMPLIANCE_TITLE_MAP.U,
    value: 'U',
  },
  {
    label: VETTING_COMPLIANCE_TITLE_MAP.X,
    value: 'X',
  },
]

const policyOptions: SelectOptionProps[] = [
  {
    label: 'Accessibility Policy',
    value: 'accessibility_url',
  },
  {
    label: 'Cookie Policy',
    value: 'cookie_url',
  },
  {
    label: 'Privacy Policy',
    value: 'privacy_url',
  },
  {
    label: 'Security Policy',
    value: 'security_url',
  },
  {
    label: 'Terms of Service',
    value: 'tos_url',
  },
]

const getDefaultRubric = () => {
  const data = new Map()
  rubricData.rubric.forEach((r: any) => {
    //eslint-disable-line
    data.set(r.question, {
      question: r.question,
      question_text: r.text,
      user_notes: '',
      user_selection: 0,
      user_selection_text: '',
    })
  })
  return data
}

export default function AddCustomTrustedAppsScreen() {
  // TODO:
  // Code splitting for performance improvements
  // Update rubric to utilize reference data from backend
  const history = useHistory()
  const me = useSelector(meSelector)
  const {
    firstName = '',
    lastName = '',
    jobTitle = '',
    organizationName = '',
  } = me!

  const [ratingsResponse] = useRecordEntity({
    record: TRUSTED_APPS_RATINGS_RECORD,
    id: 1,
    noReFetch: true,
  })

  const ratings: TrustedAppRating[] | undefined = ratingsResponse?.ratings

  const emptyVetting = {
    creation_date: new Date().getTime() / 1000,
    url: '',
    author_first_name: firstName,
    author_last_name: lastName,
    author_job_title: jobTitle,
    author_org_name: organizationName,
    rubric_version: 1,
    coppa_compliance: 'X',
    ferpa_compliance: 'X',
    gdpr_compliance: 'X',
    age_restricted: 'X',
    accessibility_url: '',
    cookie_url: '',
    privacy_url: '',
    security_url: '',
    tos_url: '',
    selections: [],
  }

  const [addVetting, setAddVetting] = React.useState(false)

  const rubric = getDefaultRubric()

  const [
    initialSupplierOptions,
    supplierOptions,
    resetSupplierOptions,
    getSupplierOptions,
  ] = useTrustedAppsSupplierOptions(true, true)

  const createCustomApp = (values: IFormValues) => {
    return {
      product_name: values.appName,
      product_description: values.appDescription,
      product_url: values.appUrl,
      org_name: values.appSupplierName,
      org_external_url: '',
      org_uuid: values.appSupplierId,
      vetting: addVetting ? values.vetting : {},
      dataSharing: '',
      licensingCoverage: [],
      licensingFees: '',
      licensingExpirationDate: 0,
      technicalNotes: '',
      instructionalNotes: '',
      coreOrSupplemental: '',
      reviwedByInstructionalDesigners: null,
      rosteringMethod: '',
      gradePassbackMethod: '',
      accessMethods: [],
      securityReview: '',
      learningStandardsAlignment: '',
      downstreamAnalytics: '',
      subjects: [],
      grades: [],
      tooluses: [],
      rating: values.appRating,
    }
  }

  const goToDashboard = () =>
    history.replace(DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE)

  const onSubmit = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    values.vettingRubric.forEach((v: any) => {
      if (!v.user_selection) v.user_selection = 0
    })
    values.vetting.selections = Array.from(values.vettingRubric.values())
    const app = createCustomApp(values)
    try {
      const { success } = await postRequest(
        'trustedapps/organizations/customApplications',
        app,
        {},
        {
          'Content-Type': 'application/json',
        },
      )
      if (success) {
        showToast('start', 'Application created successfully')
        goToDashboard()
      } else {
        showToast('error', 'Error creating application')
      }
    } catch (e) {
      showToast('error', 'Error creating application')
    }
    bag.setSubmitting(false)
  }
  return (
    <Screen>
      <DocumentTitle title="TrustEd Apps" />
      <BaseTrustedAppsBreadCrumbs
        crumbs={[{ name: 'Add Private TrustEd App' }]}
      />
      <Formik
        initialValues={{
          appName: '',
          appSupplierName: '',
          appDescription: '',
          appUrl: '',
          appStatus: 'UNRATED',
          appRating: undefined,
          vetting: emptyVetting,
          vettingRubric: rubric,
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          appName: Yup.string().required('Required'),
          appSupplierName: Yup.string().required('Required'),
          appDescription: Yup.string().required('Required'),
          appUrl: Yup.string().url('Must be a valid URL').required('Required'),
        })}
      >
        {(bag) => {
          const handleSupplierChange = (value: any, extended?: boolean) => {
            if (R.isNil(value)) {
              bag.setFieldValue('appSupplierName', value)
              return
            }
            if (extended) {
              bag.setFieldValue('appSupplierName', value)
              bag.setFieldValue('appSupplierId', '')
            } else {
              bag.setFieldValue(
                'appSupplierName',
                R.filter(R.propEq('value', value), supplierOptions)[0].label,
              )
              bag.setFieldValue('appSupplierId', value)
              resetSupplierOptions()
            }
          }
          const handleVettingChange = (value: any, field: string) => {
            bag.setFieldValue('vetting', {
              ...bag.values.vetting,
              [field]: value,
            })
          }

          const handleRubricSelect = (
            answer: VettingRubricScores,
            answerText: string,
            questionId: string,
          ) => {
            bag.values.vettingRubric.set(questionId, {
              ...bag.values.vettingRubric.get(questionId),
              user_selection: answer,
              user_selection_text: answerText,
            })
          }
          const handleNoteChange = (note: string, questionId: string) => {
            bag.values.vettingRubric.set(questionId, {
              ...bag.values.vettingRubric.get(questionId),
              user_notes: note,
            })
          }

          return (
            <Form>
              <div className="flex flex-col space-y-6">
                <Paper>
                  <WidgetHeader title="Step 1: Complete Application Information" />
                  <View mt={4} className="px-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <FormInput
                        name="appName"
                        label="Application Name"
                        required
                        value={bag.values.appName}
                        valueDataTest="data-test-app-name"
                        placeholder="Enter application name"
                        aria-label="Application Name Input"
                        handleChange={bag.handleChange}
                      />
                      <FormSelect
                        name="appSupplierName"
                        label="Application Supplier"
                        selected={bag.values.appSupplierName}
                        aria-label="Application Supplier Name Input"
                        placeholder="Enter application supplier"
                        initialOptions={initialSupplierOptions}
                        options={supplierOptions}
                        onSearchChange={getSupplierOptions}
                        onChange={handleSupplierChange}
                        large
                        required
                        extensible
                      />
                      <FormInput
                        name="appUrl"
                        label="Application URL"
                        required
                        value={bag.values.appUrl}
                        valueDataTest="data-test-app-url"
                        placeholder="Enter application URL"
                        aria-label="Application URL Input"
                        handleChange={bag.handleChange}
                      />
                      {ratings && (
                        <div>
                          <InputLabel>App Rating</InputLabel>
                          <div className="border border-gray rounded-sm p-3">
                            <TrustedAppsRatingSelect
                              applicationId={Number.MAX_SAFE_INTEGER}
                              ratingId={bag.values.appRating?.ratingId || null}
                              onChange={(ratingId) => {
                                const selectedRating = ratings.find(
                                  (r) => r.ratingId === ratingId,
                                )
                                bag.setFieldValue('appRating', selectedRating)
                              }}
                              ratings={ratings}
                            />
                          </div>
                        </div>
                      )}
                      <div className="md:col-span-2">
                        <FormInput
                          name="appDescription"
                          label="Application Description"
                          required={true}
                          value={bag.values.appDescription}
                          aria-label="Application Description Input"
                          valueDataTest="data-test-app-desc"
                          placeholder="Describe your application here"
                          handleChange={bag.handleChange}
                          textArea={true}
                          inputProps={{
                            width: '100%',
                            minHeight: '6rem',
                            className: 'resize-none',
                          }}
                        />
                      </div>
                    </div>
                  </View>
                </Paper>
                <View
                  className={clsx({
                    hidden: !addVetting,
                  })}
                >
                  <Paper className="mb-4">
                    <WidgetHeader title="Step 2: Add Organizational Vetting" />
                    <View
                      mt={4}
                      className="flex flex-row space-x-12 justify-center"
                    >
                      <View className="flex flex-col w-1/3 space-y-4">
                        {policyOptions.map((policy: any, key: number) => {
                          return (
                            <FormInput
                              name={`policy.${policy}`}
                              label={`${policy.label} URL`}
                              value={R.prop(policy.value, bag.values.vetting)}
                              valueDataTest={`data-test-${policy.value}`}
                              placeholder={`Enter ${policy.label} URL`}
                              aria-label={`${policy.label} Input`}
                              handleChange={(e: any) => {
                                handleVettingChange(
                                  e.target.value,
                                  policy.value,
                                )
                              }}
                              key={key}
                            />
                          )
                        })}
                      </View>
                      <View className="flex flex-col w-1/3 space-y-4">
                        <FormSelect
                          name="coppa compliance"
                          label="COPPA Compliance"
                          options={vettingComplianceOptions}
                          onChange={(value: any) => {
                            handleVettingChange(value, 'coppa_compliance')
                          }}
                          selected={bag.values.vetting['coppa_compliance']}
                          required
                        />
                        <FormSelect
                          name="ferpa compliance"
                          label="FERPA Compliance"
                          options={vettingComplianceOptions}
                          onChange={(value: any) => {
                            handleVettingChange(value, 'ferpa_compliance')
                          }}
                          selected={bag.values.vetting['ferpa_compliance']}
                          required
                        />
                        <FormSelect
                          name="gdpr compliance"
                          label="GDPR Compliance"
                          options={vettingComplianceOptions}
                          onChange={(value: any) => {
                            handleVettingChange(value, 'gdpr_compliance')
                          }}
                          selected={bag.values.vetting['gdpr_compliance']}
                          required
                        />
                        <FormSelect
                          name="age restricted"
                          label="Approved for Children Under 13"
                          options={vettingComplianceOptions}
                          onChange={(value: any) => {
                            handleVettingChange(value, 'age_restricted')
                          }}
                          selected={bag.values.vetting['age_restricted']}
                          required
                        />
                      </View>
                    </View>
                  </Paper>
                  <Paper>
                    <WidgetHeader title="Step 3: Add Rubric" />
                    <View className="flex flex-col space-y-2 mt-4 w-full">
                      {rubricData.rubric.map((question: any, key: number) => {
                        const questionId = question.question
                        const answerOptions = question.answers.map(
                          (answer: any) => {
                            return {
                              label: answer.text,
                              value: parseInt(answer.answer),
                            }
                          },
                        )
                        return (
                          <RubricQuestion
                            question={question.text}
                            questionId={questionId}
                            answerOptions={answerOptions}
                            onSelect={handleRubricSelect}
                            onNoteChange={handleNoteChange}
                            key={key}
                          />
                        )
                      })}
                    </View>
                  </Paper>
                </View>
                <div className="flex flex-row space-x-4 justify-end mx-6">
                  <Button
                    onClick={() => {
                      setAddVetting(!addVetting)
                      if (!addVetting)
                        bag.setFieldValue('vetting', emptyVetting)
                    }}
                    variant={addVetting ? 'error' : 'start'}
                    type="button"
                  >
                    {addVetting ? 'Remove Vetting' : 'Add Vetting'}{' '}
                  </Button>
                  <Button type="submit">Submit</Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Screen>
  )
}
