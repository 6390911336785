import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'
import * as CSS from 'csstype'

import { flexibleStyle, hoverStyle } from 'lib/components/View'
import colors from 'lib/styles/colors'
import { focusShadow } from 'lib/components/Button'

export interface IHtmlButtonElementProps // eslint-disable-line
  extends HTMLAttributes<HTMLButtonElement> {}
export interface IBadgeProps
  extends IHtmlButtonElementProps,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BackgroundColorProps,
    SS.BorderColorProps,
    SS.BorderProps,
    SS.BorderRadiusProps,
    SS.BordersProps,
    SS.BottomProps,
    SS.ColorProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  color?: CSS.Property.Color
  flexible?: string
  variant?: string
  badgeType?: string
  hover?: string
}

export const badgeTypeStyle = SS.variant({
  key: 'badgeTypeStyles',
  prop: 'badgeType',
})

export const badgeTypeStyles = {
  circle: {
    borderRadius: '50%',
    padding: '6px',
  },
}

export const variantStyle = SS.variant({ key: 'badgeStyles' })
export const badgeStyles = {
  primary: {
    color: '#fff',
    backgroundColor: colors.primary,
  },
  success: {
    color: '#fff',
    backgroundColor: colors.start,
  },
  warning: {
    color: '#fff',
    backgroundColor: colors.warningAlt,
  },
  warningAlt: {
    color: '#fff',
    backgroundColor: colors.warningAlt,
  },
  error: {
    color: '#fff',
    backgroundColor: colors.error[40],
  },
  neutral: {
    color: '#fff',
    backgroundColor: colors.text,
  },
}
// prettier-ignore
const Badge = styled.button<IBadgeProps>`
  color: #fff;
  padding: 6px 8px;
  border-radius: .25rem;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ${focusShadow}
  &:hover {
    cursor: pointer;
  }
  ${variantStyle}
  ${badgeTypeStyle}
  ${hoverStyle}
  ${flexibleStyle}
  ${flexibleStyle}
  ${SS.alignContent}
  ${SS.alignItems}
  ${SS.alignSelf}
  ${SS.bottom}
  ${SS.borderRadius}
  ${SS.color}
  ${SS.display}
  ${SS.flexBasis}
  ${SS.flexDirection}
  ${SS.flexWrap}}
  ${SS.flex}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.height}
  ${SS.justifyContent}
  ${SS.justifySelf}
  ${SS.left}
  ${SS.lineHeight}
  ${SS.maxHeight}
  ${SS.maxWidth}
  ${SS.minHeight}
  ${SS.minWidth}
  ${SS.order}
  ${SS.position}
  ${SS.right}
  ${SS.space}
  ${SS.top}
  ${SS.width}
  ${SS.zIndex}
`

Badge.displayName = 'Badge'
Badge.defaultProps = {
  variant: 'primary',
}

export default Badge
