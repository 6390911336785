import React from 'react'
import * as R from 'ramda'

import { Button, Icon, View } from 'lib/components'
import ITrustedApplication, {
  hasAdditionalInfo,
} from 'domains/trustedApps/models/ITrustedApplication'

interface IPropsNoApp {
  id: number
  additionalInfo: boolean
  onClick: (application?: ITrustedApplication) => void
}
interface IPropsApp extends ITrustedApplication {
  onClick: (application?: ITrustedApplication) => void
}

export default function TrustedAppsAdditionalInfoCell(
  props: IPropsNoApp | IPropsApp,
) {
  const hasInfo = R.propOr(
    hasAdditionalInfo({ ...props } as any),
    'additionalInfo',
    props,
  )
  const onClick = () => props.onClick(props as ITrustedApplication)

  return (
    <View flexible="column-center">
      <Button
        variant={hasInfo ? 'success' : 'complete'}
        onClick={onClick}
        data-test={`${props.id}-additional-info-btn`}
      >
        <div className="flex flex-row items-center space-x-2">
          <Icon className="fas fa-edit" color="white" />
          <p className="text-white text-sm font-bold">
            {hasInfo ? 'Edit' : 'Add'} Additional Info
          </p>
        </div>
      </Button>
    </View>
  )
}
