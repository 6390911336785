import * as R from 'ramda'

import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { showToast } from 'lib/utils/toast'

export const updateTrustedApp = async (id: number, updates: any) => {
  const { success, data } = await updateRecord(
    TRUSTED_APPS_RECORD,
    id,
    updates,
    true,
  )
  const title = R.pathOr(
    R.pathOr('App', ['application', 'productName'], data),
    ['title'],
    data,
  )
  if (success) {
    showToast('start', `Successfully updated ${title}`)
  } else {
    showToast('error', `Failed to update ${title}`)
  }
  return success
}
