import React, { useCallback, useRef } from 'react'
import * as R from 'ramda'
import { Transition } from '@headlessui/react'
import FuzzySearch from 'fuzzy-search'
import { utils } from '@ims/1edtech-frontend-common'
import clsx from 'clsx'

import SelectInput from 'lib/components/modern/Select/SelectInput'
import SelectOption, {
  SelectOptionProps,
} from 'lib/components/modern/Select/SelectOption'
import MultiSelectChip from 'lib/components/modern/Select/MultiSelectChip'
import { Keys } from 'lib/constants/keyboard'
import Spinner from 'lib/components/Spinner'
import { PlusIcon } from '@heroicons/react/solid'

const SELECT_OPTIONS_ID = 'selectOptions'
const HIDE_TIMEOUT = 150

export type SelectProps = {
  name: string
  options: SelectOptionProps[]
  initialOptions?: SelectOptionProps[]
  selected: any
  onChange: (value: any, extended?: boolean) => any
  initialLabel?: string | SelectOptionProps[]
  onSearchChange?: (search: string) => Promise<SelectOptionProps[]>
  OptionComponent?: (props: SelectOptionProps & { active: boolean }) => any
  placeholder?: string
  multiple?: boolean
  max?: number
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => any
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => any
  extensible?: boolean
  onAddOption?: () => any
  small?: boolean
  large?: boolean
}

let _ignoreHide = false
let _ignoreBlurTimeout: any
const ignoreBlur = () => {
  _ignoreHide = true
  if (_ignoreBlurTimeout) clearTimeout(_ignoreBlurTimeout)
  _ignoreBlurTimeout = setTimeout(() => {
    _ignoreHide = false
  }, HIDE_TIMEOUT + 50)
}

let _ignoreClear = false
let _ignoreClearTimeout: any
const ignoreClear = () => {
  _ignoreClear = true
  if (_ignoreClearTimeout) clearTimeout(_ignoreClearTimeout)
  _ignoreClearTimeout = setTimeout(() => {
    _ignoreClear = false
  }, HIDE_TIMEOUT + 50)
}

export default function Select({
  name,
  options,
  initialOptions,
  selected,
  onChange,
  initialLabel,
  onSearchChange,
  OptionComponent,
  placeholder,
  multiple,
  max,
  extensible,
  onBlur: propsOnBlur,
  small = false,
}: SelectProps) {
  const rootRef = useRef<HTMLDivElement>(null)
  const selectedArray = utils.array
    .ensureArray(selected)
    .filter((s: any) => ![undefined, null, ''].includes(s as any)) as any[]
  let selectedOption = (options || initialOptions).find(
    (item) => item.value === selected,
  )
  if (
    !selectedOption &&
    extensible &&
    selected &&
    !['null', 'undefined'].includes(selected)
  )
    selectedOption = { label: `${selected}`, value: `${selected}` }

  const inputTimeout = React.useRef<any>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = React.useState<string>(
    multiple
      ? ''
      : initialLabel
      ? `${initialLabel}`
      : selectedOption
      ? selectedOption.label
      : '',
  )
  // if selection cleared, remove input value
  React.useEffect(() => {
    if (!multiple && !selectedOption && inputValue.length) {
      if (_ignoreClear) _ignoreClear = false
      else setInputValue('')
    }
  }, [inputValue, selectedOption, multiple])

  const [displayOptions, setDisplayOptions] =
    React.useState<SelectOptionProps[]>(options)
  const [searching, setSearching] = React.useState(false)
  const [searcher, setSearcher] = React.useState(
    new FuzzySearch(options, ['label', 'description'], {
      caseSensitive: false,
    }),
  )

  const [isOpen, setIsOpen] = React.useState(false)
  const onToggleOpen = () => {
    if (!isOpen) focusInput()
    setIsOpen(!isOpen)
  }

  const onClickInput = () => {
    if (!isOpen) setIsOpen(true)
  }

  const onFocus = () => {
    setIsOpen(true)
    setActiveIndex(null)
    if (inputRef.current) inputRef.current.select()
  }

  React.useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      const el = R.pathOr(null, ['path', 0], e)
      if (
        el &&
        (el as Element).getAttribute &&
        (el as Element).getAttribute('id') === SELECT_OPTIONS_ID
      ) {
        setTimeout(focusInput, HIDE_TIMEOUT + 50)
        ignoreBlur()
      } else blurInput()
    }
    if (isOpen) document.addEventListener('mousedown', onMouseDown)
    return () => {
      if (isOpen) document.removeEventListener('mousedown', onMouseDown)
    }
  }, [isOpen])

  const hideTimeout = React.useRef<any>(null)

  const hideMenu = useCallback(() => {
    if (hideTimeout.current) clearTimeout(hideTimeout.current)
    hideTimeout.current = setTimeout(() => {
      if (_ignoreHide) {
        _ignoreHide = false
        return
      }

      setIsOpen(false)
      setDisplayOptions(initialOptions || options)
      if (multiple) setInputValue('')
      if (!multiple && inputValue !== selectedOption?.value) {
        setInputValue(selectedOption?.label || '')
      }
    }, HIDE_TIMEOUT)
  }, [
    initialOptions,
    inputValue,
    multiple,
    options,
    selectedOption?.label,
    selectedOption?.value,
  ])

  const [activeIndex, setActiveIndex] = React.useState<number | null>(
    multiple ? 0 : options.findIndex((item) => item.value === selected),
  )
  const [listenToMouseEvents, setListenToMouseEvents] = React.useState(true)
  const onMouseEnterItem = (index: any) => () => {
    if (listenToMouseEvents && !displayOptions[index]?.parent)
      setActiveIndex(index)
  }
  const onMouseMoved = () => setListenToMouseEvents(true)

  React.useEffect(() => {
    setDisplayOptions(options)
    setSearcher(
      new FuzzySearch(options, ['label', 'description'], {
        caseSensitive: false,
      }),
    )
  }, [options])

  const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    ignoreClear()
    const { value } = event.target
    setInputValue(value)
    setIsOpen(true)

    if (inputTimeout.current) clearTimeout(inputTimeout.current)
    inputTimeout.current = setTimeout(
      async () => {
        if (value.length > 0) {
          let newFilteredOptions: SelectOptionProps[]

          if (onSearchChange) {
            setSearching(true)
            newFilteredOptions = await onSearchChange(value)
            setSearching(false)
          } else {
            newFilteredOptions = searcher.search(value)
          }

          if (options.some((o) => o.parent)) {
            const newOptionsParentIds = newFilteredOptions.reduce(
              (agg, option) => {
                if (option.parentId) return R.uniq([...agg, option.parentId])
                return agg
              },
              [] as any[],
            )
            const parents = newOptionsParentIds.map(
              (id) => options.find((i) => i.value === id)!,
            ) as SelectOptionProps[]
            const newOptionsWithParents = parents.reduce(
              (agg, parentOption) => {
                const childrenOptions = newFilteredOptions.filter(
                  (childOption) => childOption.parentId === parentOption.value,
                )
                return [...agg, parentOption, ...childrenOptions]
              },
              [] as SelectOptionProps[],
            )
            newFilteredOptions = newOptionsWithParents
          }

          // Add custom option to end of list
          if (
            extensible &&
            !newFilteredOptions.find((o) => o.value === value) &&
            !selectedArray.find((v) => v === value)
          ) {
            const addOption = {
              label: `Add "${value}"`,
              value: value,
              leftContent: (active: boolean) => (
                <PlusIcon
                  className={clsx('h-5 w-5', {
                    'text-gray-900': !active,
                    'text-white': active,
                  })}
                />
              ),
              centered: true,
            }
            const last = newFilteredOptions[newFilteredOptions.length - 1]
            if (last && last.label.startsWith('Add ') && last.leftContent)
              newFilteredOptions[newFilteredOptions.length] = addOption
            else newFilteredOptions.push(addOption)
          }
          setDisplayOptions(newFilteredOptions)

          if (newFilteredOptions.length > 0) {
            setActiveIndex(
              newFilteredOptions[0].parent && newFilteredOptions.length > 1
                ? 1
                : 0,
            )
          }
        } else setDisplayOptions(initialOptions || options)
      },
      onSearchChange ? 250 : 100,
    )
  }

  const focusInput = () => {
    if (inputRef.current) inputRef.current.focus()
  }

  const blurInput = () => {
    if (inputRef.current) inputRef.current.blur()
  }

  const onItemClicked = React.useCallback(
    (value: any, keyboard?: boolean) => {
      let itemIndex = options.findIndex((item) => item.value === value)
      if (itemIndex < 0)
        itemIndex =
          initialOptions?.findIndex((item) => item.value === value) || -1
      const extended = !!(extensible && itemIndex < 0 && !!value)
      const item =
        extensible && itemIndex < 0 && !!value
          ? { label: value, value }
          : options[itemIndex]
      if (item) {
        ignoreBlur()
        setActiveIndex(null)
        if (multiple) {
          setInputValue('')
          if (!keyboard) {
            hideMenu()
            setIsOpen(false)
          } else setDisplayOptions(initialOptions || options)
        } else {
          ignoreClear()
          setInputValue(item.label)
          setIsOpen(false)
        }

        const newValue = multiple ? R.uniq([...selectedArray, value]) : value
        extensible ? onChange(newValue, extended) : onChange(newValue)
      }
    },
    [
      options,
      initialOptions,
      onChange,
      multiple,
      selectedArray,
      extensible,
      hideMenu,
    ],
  )

  const onRemoveSelected = React.useCallback(
    (value: any) => {
      onChange(R.without([value], selectedArray))
    },
    [onChange, selectedArray],
  )

  const onClearSelection = () => {
    setTimeout(() => {
      setIsOpen(true)
      focusInput()
    }, HIDE_TIMEOUT + 50)

    setInputValue('')
    setActiveIndex(null)
    onChange(null)
  }

  const getNextItemIndex = React.useCallback(
    (up: boolean, idx: number | null) => {
      const hasAnyParents = displayOptions.some((o) => o.parent)
      if (!hasAnyParents && idx === null) return 0
      const index = idx || 0
      const loopTo = up ? displayOptions.length - 1 : 0
      const endingValue = up ? 0 : displayOptions.length - 1
      let nextIndex = index === endingValue ? loopTo : index + (up ? -1 : 1)
      let next = displayOptions[nextIndex] as undefined | SelectOptionProps
      while (
        multiple &&
        (!!selectedArray.find((s) => s === next?.value) || next?.parent) // eslint-disable-line
      ) {
        nextIndex =
          nextIndex === endingValue ? loopTo : nextIndex + (up ? -1 : 1)
        next = displayOptions[nextIndex]
      }

      return nextIndex
    },
    [displayOptions, multiple, selectedArray],
  )

  React.useEffect(() => {
    if (isOpen) {
      const onClick = (event: MouseEvent) => {
        if (
          rootRef.current &&
          !rootRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false)
        }
      }

      window.addEventListener('mousedown', onClick)

      return () => {
        window.removeEventListener('mousedown', onClick)
      }
    }
  }, [isOpen])

  React.useEffect(() => {
    if (isOpen) {
      const onKeydown = ({ code }: any) => {
        switch (code) {
          case Keys.Enter:
            if (activeIndex !== null && !!displayOptions[activeIndex])
              onItemClicked(displayOptions[activeIndex].value, true)
            break
          case Keys.ArrowUp: {
            if (selectedArray.length >= displayOptions.length) {
              return
            }
            setListenToMouseEvents(false)

            const nextIndex = getNextItemIndex(true, activeIndex)
            setActiveIndex(nextIndex)
            break
          }
          case Keys.ArrowDown: {
            if (selectedArray.length >= displayOptions.length) {
              return
            }
            setListenToMouseEvents(false)

            const nextIndex = getNextItemIndex(false, activeIndex)
            setActiveIndex(nextIndex)
            break
          }
          case Keys.Escape:
            hideMenu()
            break
        }
      }

      document.addEventListener('keydown', onKeydown)
      return function cleanUp() {
        return document.removeEventListener('keydown', onKeydown)
      }
    }
  }, [
    isOpen,
    displayOptions,
    activeIndex,
    onItemClicked,
    multiple,
    selectedArray,
    getNextItemIndex,
    hideMenu,
  ])

  const maxReached = multiple && selectedArray.length === max

  return (
    <div className="space-y-1">
      {multiple && (
        <div className="flex flex-row items-center space-y-1 flex-wrap">
          {(selectedArray as SelectOptionProps[]).map((value) => {
            let item = options.find((item) => item.value === value)
            if (!item && initialOptions)
              initialOptions.find((item) => item.value === value)
            if (!item) item = { label: `${value}`, value }
            if (item?.label.startsWith('Add "'))
              item = {
                ...item,
                label: item.label
                  .replace('Add ', '')
                  .replace(/^"(.+(?="$))"$/, '$1')
                  .trim(),
              }
            if (item)
              return (
                <MultiSelectChip
                  key={`selected-value-${value}`}
                  name={name}
                  label={item.label}
                  value={item.value}
                  description={item.description}
                  onRemove={onRemoveSelected}
                  small={small}
                />
              )
            return null
          })}
        </div>
      )}
      <div className="mt-1 relative" ref={rootRef}>
        <div className="w-full" onClick={onClickInput}>
          <SelectInput
            name={name}
            value={inputValue}
            onChange={onInputChange}
            onFocus={onFocus}
            onBlur={propsOnBlur}
            onToggleOpen={onToggleOpen}
            hasSelection={utils.hasValue(selected) || selected === false}
            clearSelection={onClearSelection}
            ref={inputRef}
            placeholder={placeholder}
            hideClearOption={!!multiple}
            autoComplete={false}
          />
        </div>

        <Transition
          show={isOpen}
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              'absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60',
              'rounded-md py-1 text-base ring-1 ring-black ring-opacity-5',
              'overflow-auto focus:outline-none sm:text-sm',
            )}
            onMouseMove={onMouseMoved}
            id={SELECT_OPTIONS_ID}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {!maxReached &&
              !searching &&
              displayOptions.map((option, index) => (
                <div key={index} onMouseEnter={onMouseEnterItem(index)}>
                  <SelectOption
                    {...option}
                    selected={
                      Array.isArray(selected)
                        ? !!selectedArray.find((s) => s === option.value)
                        : option.value === selected
                    }
                    active={index === activeIndex}
                    onSelected={onItemClicked}
                    OptionComponent={OptionComponent}
                    disabled={
                      multiple &&
                      !!selectedArray.find((s) => s === option.value)
                    }
                    small={small}
                  />
                </div>
              ))}
            {searching && (
              <div className="w-full p-4 flex items-center justify-center space-x-2">
                <Spinner color="primary" size={18} />
                <p className="text-semibold text-center text-md text-gray-900">
                  searching
                </p>
              </div>
            )}
            {!searching && (displayOptions.length < 1 || maxReached) && (
              <div className="w-full p-4 flex items-center justify-center">
                <p className="text-semibold text-center text-md text-gray-900">
                  {maxReached
                    ? 'Maximum options selected'
                    : inputValue.length > 0
                    ? 'No results'
                    : 'No Options'}
                </p>
              </div>
            )}
          </div>
        </Transition>
      </div>
    </div>
  )
}
