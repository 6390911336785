import React from 'react'

import * as vettings from 'domains/trustedApps/models/IIMSApplicationVetting'
import { Icon } from 'lib/components'
import VettingSectionHeader from 'domains/trustedApps/components/TrustedAppVettings/VettingSectionHeader'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'
import TrustedAppVettingRubricScore from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingRubricScore'
import TrustedAppLegendKey from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppLegendKey'

type TrustedAppVettingRubricResultsProps = {
  vetting: IIMSApplicationVetting
}

export default function TrustedAppVettingRubricResults(
  props: TrustedAppVettingRubricResultsProps,
) {
  const rubricScores =
    props.vetting.rubricType === 'access'
      ? [
          vettings.VETTING_UNMET_RUBRIC_SCORE,
          vettings.VETTING_PARTIAL_RUBRIC_SCORE,
          vettings.VETTING_MEETS_RUBRIC_SCORE,
        ]
      : [
          vettings.VETTING_NA_RUBRIC_SCORE,
          vettings.VETTING_UNMET_RUBRIC_SCORE,
          vettings.VETTING_PARTIAL_RUBRIC_SCORE,
          vettings.VETTING_MEETS_RUBRIC_SCORE,
        ]
  const rubrics = {
    privacy: [
      vettings.RUBRIC_DATA_COLLECTED_PREFIX,
      vettings.RUBRIC_SECURITY_PREFIX,
      vettings.RUBRIC_THIRD_PARTY_PREFIX,
      vettings.RUBRIC_ADVERTISING_PREFIX,
    ],
    security: [
      vettings.SECURITY_RUBRIC_DOCUMENTATION_PREFIX,
      vettings.SECURITY_RUBRIC_SYSTEMS_MGMT_PREFIX,
      vettings.SECURITY_RUBRIC_THIRD_PARTY_PREFIX,
      vettings.SECURITY_RUBRIC_DATA_PREFIX,
    ],
    access: [
      vettings.ACCESSIBILITY_RUBRIC_DOCUMENTATION_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_PROCUREMENT_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_CONFORMANCE_PREFIX,
      vettings.ACCESSIBILITY_RUBRIC_ALTERNATIVES_PREFIX,
    ],
    ai: [vettings.AI_RUBRIC_AI_PREFIX],
  }
  const score = props.vetting.selections
    .map((selection) => selection.userSelection - 1)
    .reduce((acc, val) => acc + val, 0)

  return props.vetting.rubricType !== 'access' ? (
    <div className="w-full">
      <VettingSectionHeader
        title="Rubric Results"
        rightTitleContent={
          <div className="flex flex-row items-center justify-between space-x-3 lg:space-x-6">
            {rubricScores.map((score) => (
              <TrustedAppLegendKey
                key={score}
                color={vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[score]}
                label={vettings.VETTING_RUBRIC_SCORE_TITLE_MAP[score]}
              />
            ))}
          </div>
        }
      />

      <div className="mt-4 flex flex-row flex-wrap items-center justify-evenly w-full space-x-3">
        {rubrics[props.vetting.rubricType as keyof typeof rubrics].map((r) => (
          <TrustedAppVettingRubricScore
            key={r}
            vetting={props.vetting}
            categoryPrefix={r}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className="w-full">
      <VettingSectionHeader title="Rubric Results" />
      <Icon
        className={
          score > vettings.ACCESS_EMERGING_SCORE_MAX
            ? vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[3]
            : vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[1]
        }
        color={
          score > vettings.ACCESS_EMERGING_SCORE_MAX
            ? score > vettings.ACCESS_ADVANCING_SCORE_MAX
              ? vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[3]
              : vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[2]
            : vettings.VETTING_RUBRIC_SCORE_ICON_MAP[1]
        }
      />
    </div>
  )
}
