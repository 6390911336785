import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

export const TRUSTED_APPS_SURVEYS_RECORD = 'trustedAppsSurveys'

const trustedAppsSurveys: records.modules.IModule = {
  record: TRUSTED_APPS_SURVEYS_RECORD,
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_SURVEYS_RECORD,
    {},
    {
      idAttribute: () => '1',
      processStrategy: R.assoc('id', 1),
    },
  ),
  api: {
    create: () => 'trustedapps/organizations/applications/config/surveys',
    get: () => 'trustedapps/organizations/applications/config/surveys',
    getList: () => 'trustedapps/organizations/applications/config/surveys',
    update: () => 'trustedapps/organizations/applications/config/surveys',
    delete: () => 'trustedapps/organizations/applications/config/surveys',
  },
}

export default trustedAppsSurveys
