import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { Spinner, Paper, Screen, Linkify, Button, Icon } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseTrustedAppsBreadCrumbs from 'domains/trustedApps/components/BaseTrustedAppsBreadCrumbs'
import TitleBar from 'domains/trustedApps/components/TrustedAppDetails/TitleBar'
import HTML from 'lib/components/HTML'
import TrustedAppDetailLink from 'domains/trustedApps/components/TrustedAppDetails/TrustedAppDetailLink'
import CertifiedIcon from 'domains/trustedApps/components/icons/CertifiedIcon'
import VettedIcon from 'domains/trustedApps/components/icons/VettedIcon'
import { DISTRICT_TRUSTED_APPS_ROUTE } from 'domains/application/navigation/routes'
import IIMSApplication, {
  isIMSAppCertified,
  isIMSAppVetted,
} from 'domains/trustedApps/models/IIMSApplication'
import useTrustedApp from 'domains/trustedApps/hooks/useTrustedApp'
import useModalState from 'lib/hooks/useModalState'
import InfoCardItems from 'lib/components/InfoCard/InfoCardItems'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import EditAdditionalInfoModal from 'domains/trustedApps/components/EditAdditionalInfoModal'
import InfoCardItem from 'lib/components/InfoCard/InfoCardItem'
import { hasAdditionalInfo } from 'domains/trustedApps/models/ITrustedApplication'

function Link(props: { label: string; link?: string }) {
  if (!utils.hasValue(props.link)) {
    return null
  }

  return (
    <p className="text-base text-text mb-3">
      <span className="font-bold">{props.label}</span>{' '}
      <Linkify>{props.link || ''}</Linkify>
    </p>
  )
}

export default function TrustedAppDetailsScreen(props: any) {
  const [, imsAppDetails, selectedAppDetails, pending, parentOrgId] =
    useTrustedApp(props)
  const [editModalIsOpen, openEditModal, closeEditModal] = useModalState()

  if (pending || !imsAppDetails) {
    return (
      <Screen>
        <DocumentTitle title="Application Details" />
        <BaseTrustedAppsBreadCrumbs {...props} crumbs={[{ name: '...' }]} />

        <Spinner centered={true} />
      </Screen>
    )
  }

  const imsApp = imsAppDetails as IIMSApplication
  const title = R.pathOr('', ['productName'], imsApp)

  const hasInfo = hasAdditionalInfo({ ...(selectedAppDetails || {}) } as any)

  const additionInfoItems =
    selectedAppDetails && selectedAppDetails.surveyResults
      ? selectedAppDetails?.surveyResults.map((item) => ({
          label: item.questionText,
          value: item.responses
            .map((response) => response.responseText)
            .join(', '),
        }))
      : []

  const tags = selectedAppDetails?.tags || []

  return (
    <Screen>
      <DocumentTitle title={title} />
      <BaseTrustedAppsBreadCrumbs {...props} crumbs={[{ name: title }]} />

      <Paper>
        <TitleBar app={imsApp} rating={selectedAppDetails?.rating} />

        <div className="mt-4">
          {utils.hasValue(imsApp.productDescription) && (
            <div data-test="trusted-app-description">
              <HTML fontSize={16}>{imsApp.productDescription}</HTML>
            </div>
          )}

          <div className="mt-4" data-test="trusted-app-orgUrl">
            <Link label="Org Url" link={imsApp.orgExternalUrl} />
          </div>

          <div className="mt-4 md:grid grid-cols-2 gap-3">
            <TrustedAppDetailLink
              title="View Certifications"
              icon={<CertifiedIcon width={40} height={40} />}
              route={`${DISTRICT_TRUSTED_APPS_ROUTE}${
                parentOrgId ? `/shared/${parentOrgId}` : ''
              }/${imsApp.id}/certifications`}
              enabled={isIMSAppCertified(imsApp)}
            />
            <TrustedAppDetailLink
              title="View App Vetting"
              icon={<VettedIcon width={40} height={40} />}
              route={`${DISTRICT_TRUSTED_APPS_ROUTE}${
                parentOrgId ? `/shared/${parentOrgId}` : ''
              }/${imsApp.id}/vettings`}
              enabled={isIMSAppVetted(imsApp)}
            />
          </div>
        </div>
      </Paper>

      {!!selectedAppDetails && (
        <Paper mt={4}>
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
              <WidgetHeader title="Additional Info" />
              {!parentOrgId && (
                <Button
                  variant={hasInfo ? 'success' : 'complete'}
                  onClick={openEditModal}
                  data-test="edit-btn"
                >
                  <div className="flex flex-row items-center space-x-2">
                    <Icon className="fas fa-edit" color="white" />
                    <p className="text-white text-sm font-bold">
                      {hasInfo ? 'Edit' : 'Add'} Additional Info
                    </p>
                  </div>
                </Button>
              )}
            </div>

            {R.or(
              !R.isEmpty(selectedAppDetails?.technicalNotes),
              !R.isEmpty(selectedAppDetails?.instructionalNotes),
            ) && (
              <div className="flex w-full flex-col lg:flex-row lg:space-x-5">
                {!R.isEmpty(!selectedAppDetails?.technicalNotes) && (
                  <InfoCardItem
                    className="flex-1"
                    label="Technical Notes"
                    value={selectedAppDetails?.technicalNotes}
                    showIfNoValue
                    html
                    scrollable
                  />
                )}
                {!R.isEmpty(!selectedAppDetails?.instructionalNotes) && (
                  <InfoCardItem
                    className="flex-1"
                    label="Instructional Notes"
                    value={selectedAppDetails?.instructionalNotes}
                    showIfNoValue
                    html
                    scrollable
                  />
                )}
              </div>
            )}
            <InfoCardItems items={additionInfoItems} showIfNoValue />
            {tags.length > 0 && (
              <div className="flex flex-col space-y-2">
                <WidgetHeader title="Tags" />
                <div className="flex flex-wrap space-x-2">
                  {tags.map((tag) => (
                    <div key={tag} className="px-4 py-1 bg-silver rounded-full">
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Paper>
      )}

      {!parentOrgId && (
        <EditAdditionalInfoModal
          isOpen={!!selectedAppDetails && editModalIsOpen}
          onRequestClose={closeEditModal}
          app={selectedAppDetails ? selectedAppDetails : undefined}
        />
      )}
    </Screen>
  )
}
