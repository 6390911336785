import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { Formik } from 'formik'
import { Button, ModalWrapper, Text } from 'lib/components'
import React, { useEffect, useMemo } from 'react'
import { TrustedAppsCustomQuestionRow } from './TrustedAppsCustomQuestionRow'
import * as Yup from 'yup'
import { FormValues, FormSurveyQuestion } from './types'
import {
  TrustedAppQuestionType,
  UpdateTrustedAppSurveyQuestionsRequest,
} from 'domains/trustedApps/models/ITrustedAppsCustomQuestions'
import { ERROR_TOAST, showToast, SUCCESS_TOAST } from 'lib/utils/toast'
import { useQueryTrustedAppsSurveys } from 'domains/trustedApps/hooks/useQueryTrustedAppsSurveys'
import { useUpdateCustomQuestions } from 'domains/trustedApps/hooks/useUpdateCustomQuestions'

interface TrustedAppsCustomFieldsProps {
  height: number
}

const validationSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      questionText: Yup.string().required('Required'),
      responses: Yup.array()
        .of(
          Yup.object().shape({
            responseText: Yup.string().required('Required'),
          }),
        )
        .min(1, 'At least one response is required'),
    }),
  ),
})

export function TrustedAppsCustomFields({
  height,
}: TrustedAppsCustomFieldsProps) {
  const questions = useQueryTrustedAppsSurveys()
  const [putQuestions] = useUpdateCustomQuestions()

  useEffect(() => {
    questions.refetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false)

  const initialValues = useMemo((): FormValues => {
    if (!questions.data) return { questions: [] }

    const resolvedQuestions: FormSurveyQuestion[] = questions.data?.map(
      (q) => ({
        ...q,
        isCreated: false,
        responses: q.responses?.map((r) => ({ ...r, isCreated: false })) ?? [],
      }),
    )

    return { questions: resolvedQuestions }
  }, [questions])

  if (questions.loading) return null

  const hasDeletions = (values: FormValues): boolean => {
    if (!initialValues.questions) return false

    for (const question of initialValues.questions) {
      if (!values.questions.find((q) => q.id === question.id)) {
        return true
      }
    }
    return false
  }

  const onSubmit = async (values: FormValues) => {
    try {
      const data: UpdateTrustedAppSurveyQuestionsRequest = {
        questions: values.questions.map((question) => {
          const questionId = question.isCreated ? undefined : question.id

          return {
            id: questionId,
            questionType: question.questionType,
            hideFromTeachers: question.hideFromTeachers,
            questionText: question.questionText,
            responses: question.responses.map((response) => {
              const responseId = response.isCreated ? undefined : response.id

              return {
                id: responseId,
                responseText: response.responseText,
              }
            }),
          }
        }),
      }

      setShowConfirmationModal(false)

      await putQuestions(data)
      showToast(SUCCESS_TOAST, 'Custom Fields saved successfully')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      showToast(ERROR_TOAST, 'Failed to save Custom Fields')
    }
  }

  return (
    <DetailsTopWidget
      title="TrustEd App Questions & Answers"
      dataTest="trustedapps-custom-fields-widget"
      height={height}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, values, isSubmitting, resetForm }) => {
          const createNewQuestion = () => {
            const newQuestion: FormSurveyQuestion = {
              id: Math.random(),
              questionText: '',
              responses: [
                {
                  id: Math.random(),
                  responseText: '',
                  isCreated: true,
                },
              ],
              hideFromTeachers: false,
              questionType: TrustedAppQuestionType.SINGLE_RESPONSE_FROM_LIST,
              isCreated: true,
            }

            setFieldValue('questions', [...values.questions, newQuestion])
          }

          return (
            <>
              <form
                style={{ width: '100%' }}
                noValidate={true}
                data-test="login-form"
              >
                <div className="flex flex-col gap-3 px-4">
                  {values.questions.map((question) => {
                    return (
                      <TrustedAppsCustomQuestionRow
                        key={question.id}
                        question={question}
                      />
                    )
                  })}
                  <div
                    className="w-full bg-silver hover:bg-violet-95 text-gray rounded-md flex py-4 items-center justify-center cursor-pointer hover:text-violet-80"
                    onClick={createNewQuestion}
                  >
                    <i className="fas fa-plus text-xl mr-2 font-bold"></i>Add
                    New Question
                  </div>
                </div>
              </form>
              <div className="flex w-full justify-end p-4 mt-4 bottom-0 sticky bg-white z-[8]">
                <Button
                  type="button"
                  variant="complete"
                  onClick={(e: any) => {
                    hasDeletions(values)
                      ? setShowConfirmationModal(true)
                      : handleSubmit(e)
                  }}
                  pending={isSubmitting}
                >
                  Save ️{' '}
                </Button>
              </div>
              <ModalWrapper
                isOpen={showConfirmationModal}
                title="Confirm Deletions"
                pending={isSubmitting}
                actions={[
                  {
                    text: 'Revert Changes',
                    onClick: () => {
                      resetForm()
                      setShowConfirmationModal(false)
                    },
                    variant: 'neutral',
                  },
                  {
                    text: 'Confirm Deletion',
                    onClick: handleSubmit,
                    variant: 'warningAlt',
                    extra: { type: 'submit' },
                  },
                ]}
              >
                <Text>
                  Warning: Deletions of questions and answers will apply to any
                  apps using them
                </Text>
              </ModalWrapper>
            </>
          )
        }}
      </Formik>
    </DetailsTopWidget>
  )
}
