import { useState } from 'react'
import { CustomRating } from './types'
import { TRUSTED_APPS_RATINGS_RECORD } from 'lib/records/modules/trustedAppsRatings'
import { updateRecord } from 'lib/records/workflows/updateRecord'

export const usePutRatings = () => {
  const [loading, setLoading] = useState(false)

  const putRatings = async (ratings: CustomRating[]) => {
    setLoading(true)
    const saniRatings = ratings.reduce((acc, r, i) => {
      acc[i] = {
        iconColor: r.iconColor as any,
        iconIdentifier: r.iconIdentifier,
        ratingId: r.isNew ? undefined : r.ratingId,
        ratingText: r.ratingText,
        isEmbedded: r.isEmbedded,
      }
      return acc
    }, {} as Record<number, any>)

    await updateRecord(TRUSTED_APPS_RATINGS_RECORD, '1', saniRatings, true)
  }

  return [putRatings, loading] as const
}
