import React from 'react'
import clsx from 'clsx'

import { utils } from '@ims/1edtech-frontend-common'
import getOrgLogo from 'domains/orgs/utils/getOrgLogo'

type TrustedAppLogoProps = {
  title: string
  logo?: string
}

export default function TrustedAppLogo(props: TrustedAppLogoProps) {
  const [hasError, setError] = React.useState(false)
  const onError = () => setError(true)

  const hasLogo = utils.hasValue(props.logo)

  return (
    <div
      className={clsx('flex flex-col items-center justify-center w-12 h-12', {
        'bg-blue-500 rounded-full': !hasLogo,
      })}
    >
      {!hasError && hasLogo ? (
        <img
          className={clsx('w-auto max-w-12 h-auto max-h-12')}
          src={getOrgLogo(`${props.logo}`)}
          alt={`${props.title} logo`}
          onError={onError}
        />
      ) : (
        <div className={clsx('text-xl center text-white font-bold')}>
          {`${props.title}`.trim().split('')[0].toUpperCase()}
        </div>
      )}
    </div>
  )
}
