import React from 'react'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import { Spinner, Paper, Screen } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseTrustedAppsBreadCrumbs from 'domains/trustedApps/components/BaseTrustedAppsBreadCrumbs'
import TitleBar from 'domains/trustedApps/components/TrustedAppDetails/TitleBar'
import IIMSApplication, {
  getIMSAppVettings,
} from 'domains/trustedApps/models/IIMSApplication'
import useTrustedApp from 'domains/trustedApps/hooks/useTrustedApp'
import {
  getRoute,
  TRUSTED_APPS_ROUTE,
} from 'domains/application/navigation/routes'
import VettedIcon from 'domains/trustedApps/components/icons/VettedIcon'
import GenericError from 'domains/application/components/GenericError'
import {
  getVettingAuthor,
  getVettingAuthorTitle,
  VETTING_RUBRIC_TYPE_TITLE_MAP,
} from 'domains/trustedApps/models/IIMSApplicationVetting'
import TrustedAppVettingCompliance from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingCompliance'
import TrustedAppVettingPoliciesCited from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingPoliciesCited'
import TrustedAppVettingRubricResults from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingRubricResults'
import TrustedAppVettingResults from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppVettingResults'

export default function TrustedAppVettingReportScreen(props: any) {
  const [id, imsAppDetails, , pending, parentOrgId] = useTrustedApp(props)
  const { vettingId } = useParams<{ vettingId: string }>()
  const vettingIndex = utils.convertToInt(vettingId)

  if (pending) {
    return (
      <Screen>
        <DocumentTitle title="App Vetting Report..." />
        <BaseTrustedAppsBreadCrumbs {...props} crumbs={[{ name: '...' }]} />

        {pending && <Spinner centered={true} />}
        {!pending &&
          vettingIndex >
            R.pathOr([], ['vettings'], imsAppDetails).length - 1 && (
            <GenericError noScreenWrapper={true} title="Vetting not found" />
          )}
      </Screen>
    )
  }
  const imsApp = imsAppDetails as IIMSApplication
  const vettings = getIMSAppVettings(imsApp)
  const vetting = vettings[vettingIndex]

  const title = R.pathOr('', ['productName'], imsApp)
  const authorName = getVettingAuthor(vetting)
  const authorTitle = getVettingAuthorTitle(vetting)

  const baseAppRoute = `${TRUSTED_APPS_ROUTE}${
    parentOrgId ? `/shared/${parentOrgId}` : ''
  }`
  return (
    <Screen>
      <DocumentTitle title={`${title} - Vettings`} />
      <BaseTrustedAppsBreadCrumbs
        {...props}
        crumbs={[
          { name: title, route: getRoute(baseAppRoute, id) },
          {
            name: 'Vettings',
            route: getRoute(baseAppRoute, id, ['vettings']),
          },
          {
            name: `${authorName}${
              utils.hasValue(authorTitle) ? ', ' : ''
            }${authorTitle}`,
          },
        ]}
      />

      <Paper>
        <TitleBar
          app={imsApp}
          rightIcon={<VettedIcon width={40} height={40} />}
          rightTitle={`${
            VETTING_RUBRIC_TYPE_TITLE_MAP[vetting.rubricType ?? 'privacy']
          } Vetting`}
        />

        <div data-test="title" className="my-6 md:my-10">
          <h1 className="text-2xl font-light">{`${
            VETTING_RUBRIC_TYPE_TITLE_MAP[vetting.rubricType ?? 'privacy']
          } Vetting Profile`}</h1>
          <p className="text-sm mt-1" data-test="vetting-report-title">
            {utils.date.formatDate(vetting.creationDate, 'MM/DD/YYYY')}{' '}
            <span className="font-bold">by {authorName}</span>
            {utils.hasValue(authorTitle) ? `, ${authorTitle}` : ''}
          </p>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-12 xl:gap-24">
          <TrustedAppVettingCompliance vetting={vetting} />
          <div>
            <div className="mt-8 lg:mt-0">
              <TrustedAppVettingPoliciesCited vetting={vetting} />
            </div>

            <div className="mt-8 lg:mt-12">
              <TrustedAppVettingRubricResults vetting={vetting} />
            </div>
          </div>
        </div>

        <div className="mt-14">
          <TrustedAppVettingResults vetting={vetting} />
        </div>
      </Paper>
    </Screen>
  )
}
