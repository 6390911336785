import * as R from 'ramda'

import colors from 'lib/styles/colors'

export const VETTING_COMPLIANCE_UNKNOWN = 'U'
export const VETTING_COMPLIANCE_NON_COMPLIANT = 'N'
export const VETTING_COMPLIANCE_COMPLIANT = 'Y'
export const VETTING_COMPLIANCE_NA = 'X'

export type VettingComplianceTypes = 'U' | 'N' | 'Y' | 'X'

export type VettingRubricTypes = 'privacy' | 'security' | 'access' | 'ai'

export const VETTING_PRIVACY_RUBRIC_TITLE = 'Privacy'
export const VETTING_SECURITY_RUBRIC_TITLE = 'Security'
export const VETTING_ACCESSIBILITY_RUBRIC_TITLE = 'Accessibility'
export const VETTING_AI_RUBRIC_TITLE = 'AI'

export const VETTING_RUBRIC_TYPE_TITLE_MAP: { [key: string]: string } = {
  privacy: VETTING_PRIVACY_RUBRIC_TITLE,
  security: VETTING_SECURITY_RUBRIC_TITLE,
  access: VETTING_ACCESSIBILITY_RUBRIC_TITLE,
  ai: VETTING_AI_RUBRIC_TITLE,
}

export const VETTING_COMPLIANCE_TITLE_MAP: { [key: string]: string } = {
  U: 'Unknown',
  N: 'No',
  Y: 'Yes',
  X: 'N/A',
}

export const VETTING_NA_RUBRIC_SCORE = 0
export const VETTING_UNMET_RUBRIC_SCORE = 1
export const VETTING_PARTIAL_RUBRIC_SCORE = 2
export const VETTING_MEETS_RUBRIC_SCORE = 3
export type VettingRubricScores = 0 | 1 | 2 | 3

export const ACCESS_EMERGING_SCORE_MAX = 12
export const ACCESS_ADVANCING_SCORE_MAX = 26

export const VETTING_NA_RUBRIC_TITLE = 'N/A'
export const VETTING_UNMET_RUBRIC_TITLE = 'Unmet'
export const VETTING_PARTIAL_RUBRIC_TITLE = 'Partial'
export const VETTING_MEETS_RUBRIC_TITLE = 'Meets'

export const ACCESSIBILITY_VETTING_UNMET_RUBRIC_TITLE = 'Emerging'
export const ACCESSIBILITY_VETTING_PARTIAL_RUBRIC_TITLE = 'Advancing'
export const ACCESSIBILITY_VETTING_MEETS_RUBRIC_TITLE = 'Optimized'

export const ACCESSIBILITY_VETTING_RUBRIC_SCORE_TITLE_MAP: {
  [key: string | number]: string
} = {
  [VETTING_UNMET_RUBRIC_SCORE]: ACCESSIBILITY_VETTING_UNMET_RUBRIC_TITLE,
  [VETTING_PARTIAL_RUBRIC_SCORE]: ACCESSIBILITY_VETTING_PARTIAL_RUBRIC_TITLE,
  [VETTING_MEETS_RUBRIC_SCORE]: ACCESSIBILITY_VETTING_MEETS_RUBRIC_TITLE,
}

export const VETTING_RUBRIC_SCORE_TITLE_MAP: {
  [key: string | number]: string
} = {
  [VETTING_NA_RUBRIC_SCORE]: VETTING_NA_RUBRIC_TITLE,
  [VETTING_UNMET_RUBRIC_SCORE]: VETTING_UNMET_RUBRIC_TITLE,
  [VETTING_PARTIAL_RUBRIC_SCORE]: VETTING_PARTIAL_RUBRIC_TITLE,
  [VETTING_MEETS_RUBRIC_SCORE]: VETTING_MEETS_RUBRIC_TITLE,
}

export const VETTING_RUBRIC_SCORE_ICON_MAP: { [key: string | number]: string } =
  {
    [VETTING_NA_RUBRIC_SCORE]: 'fas fa-ban',
    [VETTING_UNMET_RUBRIC_SCORE]: 'fas fa-x',
    [VETTING_PARTIAL_RUBRIC_SCORE]: 'fas fa-check',
    [VETTING_MEETS_RUBRIC_SCORE]: 'fas fa-check',
  }
export const VETTING_RUBRIC_SCORE_BG_COLOR_MAP: {
  [key: string | number]: string
} = {
  [VETTING_NA_RUBRIC_SCORE]: 'bg-ta-gray-4',
  [VETTING_UNMET_RUBRIC_SCORE]: 'bg-error-30',
  [VETTING_PARTIAL_RUBRIC_SCORE]: 'bg-warning',
  [VETTING_MEETS_RUBRIC_SCORE]: 'bg-success-20',
}
export const VETTING_RUBRIC_SCORE_BG_HEX_MAP: {
  [key: string | number]: string
} = {
  [VETTING_NA_RUBRIC_SCORE]: colors['ta-gray-4'],
  [VETTING_UNMET_RUBRIC_SCORE]: colors['error']['30'],
  [VETTING_PARTIAL_RUBRIC_SCORE]: colors['warning'],
  [VETTING_MEETS_RUBRIC_SCORE]: colors['success']['20'],
}
export const VETTING_RUBRIC_SCORE_TEXT_COLOR_MAP: {
  [key: string | number]: string
} = {
  [VETTING_NA_RUBRIC_SCORE]: 'text-ta-gray-4',
  [VETTING_UNMET_RUBRIC_SCORE]: 'text-error-30',
  [VETTING_PARTIAL_RUBRIC_SCORE]: 'text-warning',
  [VETTING_MEETS_RUBRIC_SCORE]: 'text-success-20',
}
export interface IIMSApplicationVettingSelection {
  question: string
  questionText: string
  userNotes: string
  userSelection: VettingRubricScores
  userSelectionText?: string
}
export default interface IIMSApplicationVetting {
  creationDate: number
  url: string
  authorFirstName: string
  authorLastName: string
  authorJobTitle: string
  authorOrgName: string
  rubricVersion: number
  rubricType: VettingRubricTypes | undefined
  coppaCompliance: VettingComplianceTypes
  ferpaCompliance: VettingComplianceTypes
  gdprCompliance: VettingComplianceTypes
  ageRestricted: VettingComplianceTypes
  accessibilityCompliance: VettingComplianceTypes
  accessibilityUrl: string
  cookieUrl: string
  privacyUrl: string
  securityUrl: string
  tosUrl: string
  selections: IIMSApplicationVettingSelection[]
}

export const getVettingAuthor = (vetting: IIMSApplicationVetting) => {
  const authorName = [
    R.pathOr('', ['authorFirstName'], vetting),
    R.pathOr('', ['authorLastName'], vetting),
  ].join(' ')
  return authorName
}

export const getVettingAuthorTitle = (vetting: IIMSApplicationVetting) => {
  const authorTitle = [
    R.pathOr('', ['authorJobTitle'], vetting),
    R.pathOr('', ['authorOrgName'], vetting),
  ].join(' at ')
  return authorTitle
}

export const RUBRIC_GENERAL_PREFIX = 'GEN'
export const RUBRIC_DATA_COLLECTED_PREFIX = 'DC'
export const RUBRIC_SECURITY_PREFIX = 'SEC'
export const RUBRIC_THIRD_PARTY_PREFIX = 'SHR'
export const RUBRIC_ADVERTISING_PREFIX = 'ADV'

export const ACCESSIBILITY_RUBRIC_DOCUMENTATION_PREFIX = 'D'
export const ACCESSIBILITY_RUBRIC_PROCUREMENT_PREFIX = 'P'
export const ACCESSIBILITY_RUBRIC_CONFORMANCE_PREFIX = 'C'
export const ACCESSIBILITY_RUBRIC_ALTERNATIVES_PREFIX = 'A'

export const SECURITY_RUBRIC_DOCUMENTATION_PREFIX = 'DOC'
export const SECURITY_RUBRIC_DATA_PREFIX = 'DA'
export const SECURITY_RUBRIC_SYSTEMS_MGMT_PREFIX = 'SM'
export const SECURITY_RUBRIC_THIRD_PARTY_PREFIX = 'TP'

export const AI_RUBRIC_AI_PREFIX = 'AI'

export const RUBRIC_GENERAL_TITLE = 'General'
export const RUBRIC_DATA_COLLECTED_TITLE = 'Data Collection'
export const RUBRIC_SECURITY_TITLE = 'Security'
export const RUBRIC_THIRD_PARTY_TITLE = 'Third Party Data'
export const RUBRIC_ADVERTISING_TITLE = 'Advertising'

export const ACCESSIBILITY_RUBRIC_DOCUMENTATION_TITLE = 'Information'
export const ACCESSIBILITY_RUBRIC_PROCUREMENT_TITLE = 'Procurement'
export const ACCESSIBILITY_RUBRIC_CONFORMANCE_TITLE = 'Conformance'
export const ACCESSIBILITY_RUBRIC_ALTERNATIVES_TITLE = 'Alternatives'

export const SECURITY_RUBRIC_DOCUMENTATION_TITLE = 'Documentation'
export const SECURITY_RUBRIC_DATA_TITLE = 'Data'
export const SECURITY_RUBRIC_SYSTEMS_MGMT_TITLE = 'Systems Management'
export const SECURITY_RUBRIC_THIRD_PARTY_TITLE = 'Third-Party'

export const AI_RUBRIC_AI_TITLE = 'AI'

export const RUBRIC_PREFIX_TITLE_MAP: { [key: string]: string } = {
  [RUBRIC_GENERAL_PREFIX]: RUBRIC_GENERAL_TITLE,
  [RUBRIC_DATA_COLLECTED_PREFIX]: RUBRIC_DATA_COLLECTED_TITLE,
  [RUBRIC_SECURITY_PREFIX]: RUBRIC_SECURITY_TITLE,
  [RUBRIC_THIRD_PARTY_PREFIX]: RUBRIC_THIRD_PARTY_TITLE,
  [RUBRIC_ADVERTISING_PREFIX]: RUBRIC_ADVERTISING_TITLE,

  [ACCESSIBILITY_RUBRIC_DOCUMENTATION_PREFIX]:
    ACCESSIBILITY_RUBRIC_DOCUMENTATION_TITLE,
  [ACCESSIBILITY_RUBRIC_PROCUREMENT_PREFIX]:
    ACCESSIBILITY_RUBRIC_PROCUREMENT_TITLE,
  [ACCESSIBILITY_RUBRIC_CONFORMANCE_PREFIX]:
    ACCESSIBILITY_RUBRIC_CONFORMANCE_TITLE,
  [ACCESSIBILITY_RUBRIC_ALTERNATIVES_PREFIX]:
    ACCESSIBILITY_RUBRIC_ALTERNATIVES_TITLE,

  [SECURITY_RUBRIC_DOCUMENTATION_PREFIX]: SECURITY_RUBRIC_DOCUMENTATION_TITLE,
  [SECURITY_RUBRIC_DATA_PREFIX]: SECURITY_RUBRIC_DATA_TITLE,
  [SECURITY_RUBRIC_SYSTEMS_MGMT_PREFIX]: SECURITY_RUBRIC_SYSTEMS_MGMT_TITLE,
  [SECURITY_RUBRIC_THIRD_PARTY_PREFIX]: SECURITY_RUBRIC_THIRD_PARTY_TITLE,

  [AI_RUBRIC_AI_PREFIX]: AI_RUBRIC_AI_TITLE,
}
