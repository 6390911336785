import React, { PureComponent } from 'react'
import * as R from 'ramda'
import Modal from 'react-modal'

import { Button, Spinner, Text, View } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { IViewProps } from 'lib/components/View'
import { SMALL_SCREEN_SIZE } from 'lib/styles/screenSizes'

export interface IModalAction {
  text: string
  onClick: () => any
  variant?: string
  extra?: any
}
interface IProps {
  isOpen: boolean
  title: string | any
  notice?: string
  actions: IModalAction[]
  children: any
  pending?: boolean
  bodyProps?: IViewProps
  overflowInitial?: boolean
  maxWidth?: string | number | (string | number)[]
}

export default class ModalWrapper extends PureComponent<IProps> {
  renderAction = (options: IModalAction, index: number) => (
    <Button
      variant={
        R.pathOr(false, ['extra', 'disabled'], options)
          ? 'disabled'
          : options.variant
      }
      onClick={options.onClick}
      ml={[0, index > 0 ? 2 : 0]}
      mt={[2, 0]}
      key={`modal-action-${index}`}
      data-test={`modal-action-${options.text}`}
      aria-label={`${options.text} Modal Button`}
      {...options.extra}
    >
      {options.text}
    </Button>
  )

  renderActions() {
    if (this.props.pending) {
      return <Spinner size={28} />
    }

    return this.props.actions.map(this.renderAction)
  }

  render() {
    const { isOpen, title, notice, children } = this.props

    const width = this.props.maxWidth || ['100%', SMALL_SCREEN_SIZE - 60]
    return (
      <Modal
        isOpen={isOpen}
        closeTimeoutMS={500}
        id={this.props.overflowInitial ? 'overflow-initial' : ''}
        style={{ content: { overflow: 'hidden' } }}
      >
        <View flexible="column" flex={1} maxWidth={width} minWidth={width}>
          <View
            flexible="column-space-between"
            justifyContent="center"
            p={[2, 3, 4]}
            borderBottom="1px solid #dcdcdc"
            minHeight={96}
            position="sticky"
            top={0}
            bg="white"
            boxSizing="border-box"
          >
            <View flexible="row-space-between" width="100%">
              <View flex={1}>
                {R.is(String, title) && (
                  <Text
                    fontSize={[18, 24]}
                    lineHeight={['24px', '32px']}
                    fontWeight={700}
                    pr={3}
                    wordBreak="break-word"
                    data-test="modal-title"
                  >
                    {title}
                  </Text>
                )}
                {!R.is(String, title) && (
                  <View
                    pr={3}
                    data-test="modal-title"
                    fontSize={[18, 24]}
                    lineHeight={['24px', '32px']}
                    wordBreak="break-word"
                  >
                    {title}
                  </View>
                )}
              </View>

              <View flexible="row-h-end-wrap" data-test="modal-actions">
                {this.renderActions()}
              </View>
            </View>

            {utils.hasValue(notice) && (
              <Text
                variant="caption"
                wordBreak="break-word"
                mt={16}
                data-test="modal-notice"
              >
                {notice}
              </Text>
            )}
          </View>
          {children && (
            <View
              flexible="column"
              p={[2, 4, 4]}
              data-test="modal-body"
              className={`body ${
                this.props.overflowInitial ? 'overflow-initial' : ''
              }`}
              {...this.props.bodyProps}
            >
              {children}
            </View>
          )}
        </View>
      </Modal>
    )
  }
}
