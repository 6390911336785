import React from 'react'
import { SelectorIcon, XCircleIcon } from '@heroicons/react/solid'
import Input, { InputProps } from 'lib/components/modern/Input'

type Props = InputProps & {
  onToggleOpen: () => void
  hasSelection: boolean
  clearSelection: () => any
  hideClearOption: boolean
}

const SelectInput = React.forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const { onToggleOpen, clearSelection, ...inputProps } = props

    const onClear = (e: React.MouseEvent<any>) => {
      e.stopPropagation()
      e.preventDefault()
      clearSelection()
    }

    const onToggle = (e: React.MouseEvent<any>) => {
      e.stopPropagation()
      e.preventDefault()
      onToggleOpen()
    }

    return (
      <Input
        ref={ref}
        {...inputProps}
        rightContent={
          props.hasSelection && !props.hideClearOption ? (
            <XCircleIcon
              className="h-5 w-5 text-gray hover:text-red-500 cursor-pointer"
              aria-hidden="true"
              onClick={onClear}
              data-test={`clear-${inputProps.name}`}
            />
          ) : (
            <SelectorIcon
              className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
              aria-hidden="true"
              onClick={onToggle}
            />
          )
        }
        className="cursor-default"
      />
    )
  },
)

export default SelectInput
